import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  Card,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import RemoveIcon from '@material-ui/icons/Remove'
import { useSelector } from 'react-redux'

import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10
  },
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  h2: {
    paddingTop: theme.spacing(1)
  },
  header: {
    marginBottom: theme.spacing(-7)
  }
}))

const StatisticsFidelidade = (props) => {
  const { className, isLoading, ...rest } = props
  const classes = useStyles()

  const percentualParticipacao = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardHome ? state.dashboard.dashboardHome.percentualParticipacao : undefined) : undefined))
  const metasPercentualParticipacao = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardHome ? state.dashboard.dashboardHome.metasPercentualParticipacao : undefined) : undefined))

  const handleVariant = () => {
    let isMetaHigher = metasPercentualParticipacao > percentualParticipacao;
    let isMetaEqual = metasPercentualParticipacao == percentualParticipacao;

    let sub = metasPercentualParticipacao - percentualParticipacao;
    let variant = (isMetaHigher ? sub : sub * -1).toFixed(2).replace(".", ",");

    let variantObj = { title: '', icon: null }

    if (isMetaHigher) {
      variantObj.title = `${variant} Pontos Percentuais abaixo da meta da loja.`
      variantObj.icon = <ArrowDropDownIcon style={{ color: '#880000', marginTop: 10 }} />
    } else {
      variantObj.title = `${variant} Pontos Percentuais acima da meta da loja.`
      variantObj.icon = <ArrowDropUpIcon style={{ color: '#008800', marginTop: 10 }} />
    }

    if (isMetaEqual) {
      variantObj.title = 'Equivalente a meta da loja'
      variantObj.icon = <RemoveIcon style={{ color: '#777', marginTop: 10 }} />
    }

    return (
      <Tooltip arrow title={variantObj.title}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          {variantObj.icon}

          <Typography className={classes.h2} variant="h2">{isLoading ? <Skeleton /> : variant + ' p.p.'}</Typography>
        </Box>
      </Tooltip>
    )
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid className={classes.item} item md={4} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Participação de Fidelizações
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading ? (
              <Skeleton />
            ) :
              Intl.NumberFormat('pt-BR', {
                currency: 'BRL'
              }).format((isNaN(percentualParticipacao) ? 0 : percentualParticipacao)) + '%'
            }
          </Typography>
        </Grid>        
        <Grid className={classes.item} item md={4} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Meta de Fidelizações
          </Typography>
          <Typography className={classes.h2} variant="h2">
            {isLoading ? (
              <Skeleton />
            ) : (
              Intl.NumberFormat('pt-BR', {
                currency: 'BRL'
              }).format((isNaN(metasPercentualParticipacao) ? 0 : metasPercentualParticipacao)) + '%'
            )}
          </Typography>
        </Grid>
        <Grid className={classes.item} item md={4} sm={6} xs={12}>
          <Typography gutterBottom variant="overline">
            Pontos Percentuais
          </Typography>
          {handleVariant()}
        </Grid>
      </Grid>
    </Card>
  )
}

StatisticsFidelidade.propTypes = {
  className: PropTypes.string,
  dashboardHome: PropTypes.object.isRequired
}

export default StatisticsFidelidade
