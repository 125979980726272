import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tab,
  Tabs
} from '@material-ui/core'
import moment from 'moment'
import palette from 'theme/palette'
import { useSelector } from 'react-redux'
import { options } from './options'
import { Skeleton } from '@material-ui/lab'
import { useEffect } from 'react'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tab: {
    minWidth: 80
  }
}))

const FidelizacoesTrocasChartCard = (props) => {

  const { className, ...rest } = props
  const graficoFidelizacoesTrocas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.graficoFidelizacoesTrocas : undefined) : undefined))
  const graficoFidelizacoesTrocasHora = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.graficoFidelizacoesTrocasHora : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  const [tab, setTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  // Necessário para que não ocorra erro ao trocar de aba e manter o mesmo gráfico
  const datasetKeyProvider = () => {
    return Math.random();
  }

  const generateDataSets = (graficoFidelizacoes) => {
    return [
      {
        label: 'Fidelizações',
        yAxisID: 'A',
        backgroundColor: palette.error.main,
        data: graficoFidelizacoes.series[0]
      },
      {
        label: 'Trocas',
        yAxisID: 'B',
        backgroundColor: palette.primary.main,
        data: graficoFidelizacoes.series[1]
      },
      {
        label: 'Participação',
        yAxisID: 'C',
        data: graficoFidelizacoes.series[2],
        percentage: true,
        type: 'line'
      },
      tab == 0 ?
        {
          label: 'Meta de Participação',
          yAxisID: 'C',
          data: graficoFidelizacoes.series[3],
          percentage: true,
          borderColor: '#080',
          backgroundColor: '#FFF',
          type: 'line'
        }
      : {}
    ]
  }

  const generateData = () => {
    let data = [];

    if (tab == 0) {
      if (graficoFidelizacoesTrocas) {
        data = {
          labels: graficoFidelizacoesTrocas.options.map(d => moment(d).format('DD/MM/YYYY')),
          datasets: generateDataSets(graficoFidelizacoesTrocas)
        }
      }
    } else {
      if (graficoFidelizacoesTrocasHora) {
        data = {
          labels: graficoFidelizacoesTrocasHora.options.map(h => h + ":00"),
          datasets: generateDataSets(graficoFidelizacoesTrocasHora)
        }
      }
    }

    return data
  }
      
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
          >
            <Tab label="Dias" className={classes.tab} />
            <Tab label="Horário" className={classes.tab} />
          </Tabs>
        }
        title="GRÁFICO DE FIDELIZAÇÕES X TROCAS POR PERÍODO"
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        {isLoading ? (
          <Skeleton animation="wave" style={{ marginTop: -140 }} height={600} />
        ) : (
          <Bar data={generateData} datasetKeyProvider={datasetKeyProvider} options={options} />
        )}
      </CardContent>
      <Divider />
    </Card>
  )
}

FidelizacoesTrocasChartCard.propTypes = {
  className: PropTypes.string
}

export default FidelizacoesTrocasChartCard
