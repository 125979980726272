import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const totvsInsumoAction = {
  getTotvsInsumo,
  getTotvsInsumoById,
  getTotvsInsumoSelect,
  getTotvsInsumoSelectComprasProdutos,
  getTotvsInsumoArquivo,
  addTotvsInsumo,
  onChangeProps,
  editTotvsInsumoInfo,
  editTotvsInsumosDetails,
  createTotvsInsumo,
  deleteTotvsInsumoById,
  changeDetailsTotvsInsumo,
  onChangeDetailsTotvsInsumo,
  clear,
  clearAll
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: '',
  ShowExcluidos: false,
};

function getTotvsInsumo(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTotvsInsumoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumos/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumosList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}


function getTotvsInsumoSelectComprasProdutos() {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumos/select/comprasProdutos';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumosList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getTotvsInsumoArquivo(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumos/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTotvsInsumosList(response.data.items, response.data.totalRows))

        const BOM = '\uFEFF';
        var FileSaver = require('file-saver');
        var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });

        FileSaver.saveAs(blob, "insumos.csv");
        toast.success('Feito! Download do arquivo realizado com sucesso! 😎')

        dispatch(notIsLoading())
        dispatch(clear())
        getTotvsInsumo()
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createTotvsInsumo(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumos/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createTotvsInsumoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/insumos')
        } else {
          toast.err('Oops! Erro ao cadastrar totvsInsumo! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar totvsInsumo! 😥')
        return err.response;
      })
  }
}

function getTotvsInsumoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'totvsInsumos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editTotvsInsumosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editTotvsInsumoInfo(id, payload, redirect) {
  return (dispatch) => {
    if (redirect) {
      dispatch(isLoading())
    }
    let apiEndpoint = 'totvsInsumos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedTotvsInsumoInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/insumos')
          }
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar totvsInsumo! 😥')
        return err.response;
      })
  }
}

function deleteTotvsInsumoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'totvsInsumos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteTotvsInsumosDetails())
      dispatch(getTotvsInsumo(filterModel))
      dispatch(notIsLoading())
    })
  }
}

function changeDetailsTotvsInsumo(totvsInsumo) {
  return (dispatch) => {
    if (totvsInsumo) {
      dispatch(onChangeDetailsTotvsInsumo(totvsInsumo))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsTotvsInsumo(totvsInsumo) {
  return [
    {
      type: 'CHANGE_DETAILS_TOTVS_INSUMO',
      id: totvsInsumo.id,
      codigo: totvsInsumo.codigo,
      nome: totvsInsumo.nome,
      aproveitamento: totvsInsumo.aproveitamento,
      embalagem: totvsInsumo.embalagem,
      idUnidade: totvsInsumo.idUnidade,
      revisado: totvsInsumo.revisado,
      contabilizado: totvsInsumo.contabilizado,
      totvsInsumosConversoes: totvsInsumo.totvsInsumosConversoes,
      totvsInsumosPrecosReferencias: totvsInsumo.totvsInsumosPrecosReferencias,
    }
  ]
}

export function addTotvsInsumo(payload) {
  return [{ type: 'ADD_TOTVS_INSUMO', totvsInsumo: payload }, clear()]
}

export function changeTotvsInsumosList(totvsInsumo, totalRows) {
  return {
    type: 'FETCHED_ALL_TOTVS_INSUMO',
    totvsInsumo: totvsInsumo,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'TOTVS_INSUMO_CLEAR'
  }
}

export function clearTotvsInsumosFornecedores() {
  return {
    type: 'TOTVS_INSUMO_CLEAR_TOTVS_INSUMOS_FORNECEDORES'
  }
}

export function clearAll() {
  return {
    type: 'TOTVS_INSUMO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'TOTVS_INSUMO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editTotvsInsumosDetails(totvsInsumo) {
  return {
    type: 'TOTVS_INSUMO_DETAIL',
    id: totvsInsumo.id,
    nome: totvsInsumo.nome,
    codigo: totvsInsumo.codigo,
    aproveitamento: totvsInsumo.aproveitamento,
    embalagem: totvsInsumo.embalagem,
    idUnidade: totvsInsumo.idUnidade,
    unidade: totvsInsumo.unidade,
    revisado: totvsInsumo.revisado,
    contabilizado: totvsInsumo.contabilizado,
    excluido: totvsInsumo.excluido,
    totvsInsumosConversoes: totvsInsumo.totvsInsumosConversoes,
    totvsInsumosPrecosReferencias: totvsInsumo.totvsInsumosPrecosReferencias,
  }
}

export function updatedTotvsInsumoInfo() {
  return {
    type: 'TOTVS_INSUMO_UPDATED'
  }
}

export function createTotvsInsumoInfo() {
  return {
    type: 'TOTVS_INSUMO_CREATED_SUCCESSFULLY'
  }
}

export function deleteTotvsInsumosDetails() {
  return {
    type: 'DELETED_TOTVS_INSUMO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'TOTVS_INSUMO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TOTVS_INSUMO_NOTISLOADING'
  }
}
