import React, { useState } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { history } from '../utils'
import clsx from 'clsx'
import { AccessControl } from '../components/Utils/AccessControl'
import { toast } from 'react-toastify'
import { listaPrecoAction } from '../_actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(() => ({
  root: {}
}))

const push = (props) => {
  if (!props.href) {
    toast.warning(`Caminho '${props.href}' não encontrado!`)
    return
  }

  history.push(`${props.href}`)
}

const TitleContentListaPreco = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [order] = useState('nome');
  const [direction] = useState('asc');

  const handleExportar = () => {
    let filterModel;

    const fetchData = (page, limit, term) => {

      filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
      };

      dispatch(listaPrecoAction.getListaPrecoArquivo(filterModel));
    };

    fetchData(page, limit, term, order, direction);

    dispatch(listaPrecoAction.getListaPreco(filterModel, true));
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            {props.subTitle}
          </Typography>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Grid>
        <AccessControl
          rule={props.rule} //permissão necessária para acessar conteúdo
          yes={() => (
            <Grid item style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlgin: 'right',
              justifyContent: 'right',
              spacing: 5,
              gap: 10
            }}>
              <Button
                variant="contained"
                color="default"
                onClick={() => handleExportar()}
              >
                EXPORTAR
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => push(props)}
              >
                NOVO
              </Button>
            </Grid>
          )}
        />
      </Grid>
      <br></br>
      {props.length !== undefined && props.length !== 0 ? (
        <Grid item>
          <Typography variant="body2" align="left" gutterBottom>
            Total de dados encontrados: <b>{props.length}</b>
          </Typography>
        </Grid>
      ) : (
        <Typography variant="body2" align="left" gutterBottom>
          <b>{'Nenhum dado encontrado.'}</b>
        </Typography>
      )}
    </div>
  )
}

TitleContentListaPreco.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  rule: PropTypes.string
}
export default TitleContentListaPreco
