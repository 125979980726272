import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const certificadoAction = {
  getCertificado,
  getCertificadoSelect,
  getCertificadoById,
  download,
  createCertificado,
  onChangeProps,
  editCertificadoInfo,
  editCertificadosDetails,
  deleteCertificadoById,
  notifyCertificadoStatus,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getCertificado(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'certificados' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCertificadosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getCertificadoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'certificados/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeCertificadosList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getCertificadoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'certificados/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editCertificadosDetails(response.data))
    })
  }
}

function download(fileName) {
  return (dispatch) => {
    dispatch(isLoading())
    
    let apiEndpoint = 'certificados/S3/' + fileName
    crudService.get(apiEndpoint).then((response) => {
      if (response) {
        var FileSaver = require('file-saver');

        const blob = new Blob([response.data]);

        FileSaver.saveAs(blob, fileName);
      }

      dispatch(notIsLoading())
    })
    .catch((err) => {
      dispatch(notIsLoading())
      toast.error('Oops! Erro ao baixar certificado! 😥')
      return err.response;
    })
  }
}

function createCertificado(payload) {
  return (dispatch) => {
    dispatch(isLoading())

    let apiEndpoint = 'certificados/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createCertificadoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/certificados')
        } else {
          toast.err('Oops! Erro ao cadastrar certificado! 😥')
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar certificado! 😥')
        return err.response;
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editCertificadoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'certificados/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedCertificadoInfo())
          dispatch(clear())
          if (redirect) {
            dispatch(notIsLoading())
            history.push('/certificados')
          }
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar certificado! 😥')
        return err.response;
      }
    )
  }
}

function deleteCertificadoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'certificados/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteCertificadosDetails())
      dispatch(getCertificado(filterModel))
      dispatch(notIsLoading())
      history.push('/certificados')
    })
  }
}

function notifyCertificadoStatus(idLoja) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'certificados/loja/' + idLoja
    crudService.get(apiEndpoint).then((response) => {
      let certificado = response.data;

      if (certificado) {
        let dataValidade = new Date(certificado.dataValidade).getTime()
        let dataAtual = new Date().setHours(0, 0, 0, 0)

        // Converte a diferença em milissegundos para dias
        var diferencaEmDias = Math.ceil((dataValidade - dataAtual) / (1000 * 60 * 60 * 24));

        if (dataValidade < dataAtual) {
          toast.error('O certificado da loja está vencido!')
        }

        if (diferencaEmDias > 0 && diferencaEmDias <= 15) {
          toast.warning(`O certificado da loja vence em ${diferencaEmDias} dia(s)`)
        }
      }
    })
  }
}

export function addCertificado(payload) {
  return [{ type: 'ADD_CERTIFICADO', certificado: payload }, clear()]
}

export function changeCertificadosList(certificado, totalRows) {
  return {
    type: 'FETCHED_ALL_CERTIFICADO',
    certificado: certificado,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'CERTIFICADO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'CERTIFICADO_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'CERTIFICADO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editCertificadosDetails(certificado) {
  return [
    { type: 'CERTIFICADO_DETAIL', certificado },
  ]
}

export function updatedCertificadoInfo() {
  return {
    type: 'CERTIFICADO_UPDATED'
  }
}

export function createCertificadoInfo() {
  return {
    type: 'CERTIFICADO_CREATED_SUCCESSFULLY'
  }
}

export function deleteCertificadosDetails() {
  return {
    type: 'DELETED_CERTIFICADO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'CERTIFICADO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'CERTIFICADO_NOTISLOADING'
  }
}
