import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import { authActions } from '../_actions/'

export const lojaAction = {
  getLoja,
  getLojaStatus,
  getLojaSelect,
  addLoja,
  getLojaById,
  onChangeProps,
  editLojaInfo,
  editLojasDetails,
  editLojaEmailInfo,
  createLoja,
  deleteLojaById,
  clear,
  clearAll,
  clearUsuariosLojas
}

let filterModel = {
  Page: 1,
  Limit: 10,
  Term: ''
};

function getLoja(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'lojas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeLojasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      })
  }
}

function getLojaStatus(status) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'lojas/statusTotvs/' + status;
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeLojasList(response.data, response.data.length))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function getLojaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'lojas/select';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeLojasList(response.data, 0))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function createLoja(payload, props) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'lojas/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createLojaInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(authActions.refresh())
          dispatch(notIsLoading())
          history.push('/lojas') // retorna para a lista e atualiza
        } else {
          toast.err('Oops! Erro ao cadastrar loja! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar loja! 😥')
        return err.response;
      })
  }
}


function getLojaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'lojas/admin/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editLojasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function editLojaInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'lojas/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedLojaInfo())
          if (redirect) {
            dispatch(clear())
            dispatch(authActions.refresh())
            dispatch(notIsLoading())
            history.push('/lojas')
          }
        }// retorna para a lista e atualiza        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar loja! 😥')
        return err.response;
      })
  }
}

function editLojaEmailInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'lojas/email/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedLojaEmailInfo())
          dispatch(clear())
          dispatch(authActions.refresh())
          dispatch(notIsLoading())
          history.push('/lojas')
        }// retorna para a lista e atualiza        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar e-mail da loja! 😥')
        return err.response;
      })
  }
}

function deleteLojaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'lojas/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteLojasDetails())
      dispatch(authActions.refresh())
      dispatch(getLoja(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addLoja(payload, props) {
  return [{ type: 'ADD_LOJA', loja: payload },
  clear()
  ]
}

export function changeLojasList(loja, totalRows) {
  return {
    type: 'FETCHED_ALL_LOJA',
    loja: loja,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'LOJA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'LOJA_CLEAR_ALL'
  }
}

export function clearUsuariosLojas() {
  return {
    type: 'LOJA_CLEAR_USUARIOS_LOJAS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'LOJA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editLojasDetails(loja) {
  return {
    type: 'LOJA_DETAIL',
    id: loja.id,
    idTotvs: loja.idTotvs,
    idYungas: loja.idYungas,
    nome: loja.nome,
    codigoEstabelecimento: loja.codigoEstabelecimento,
    cnpj: loja.cnpj,
    ie: loja.ie,
    idCidade: loja.idCidade,
    idEstado: loja.idEstado,
    qrCode: loja.qrCode,
    latitude: loja.latitude,
    longitude: loja.longitude,
    telefone: loja.telefone,
    usuariosLojas: loja.usuariosLojas,
    excluido: loja.excluido,
    cidade: loja.cidade,
    estado: loja.estado,
    caminhoImagem: loja.caminhoImagem,
    toGo: loja.toGo,
    delivery: loja.delivery,
    email: loja.email,
    tipoIntegracao: loja.tipoIntegracao,
    razaoSocial: loja.razaoSocial,
    regimeTributario: loja.regimeTributario,
    senhaEmail: loja.senhaEmail,
    dataUltimaAtualizacaoCardapio: loja.dataUltimaAtualizacaoCardapio,
    dataUltimaVendaImportada: loja.dataUltimaVendaImportada
  }
}

export function updatedLojaInfo() {
  return {
    type: 'LOJA_UPDATED'
  }
}

export function updatedLojaEmailInfo() {
  return {
    type: 'LOJAEMAIL_UPDATED'
  }
}

export function createLojaInfo() {
  return {
    type: 'LOJA_CREATED_SUCCESSFULLY'
  }
}

export function deleteLojasDetails() {
  return {
    type: 'DELETED_LOJA_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'LOJA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'LOJA_NOTISLOADING'
  }
}
