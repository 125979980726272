import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  Avatar
} from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People';
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  progress: {
    marginTop: theme.spacing(1),
    height: 5,
    borderRadius: 5
  }
}))

const ClientesChartCard = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const clientesFidelizados = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.clientesFidelizados : undefined) : undefined))
  const totalClientes = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.totalClientes : undefined) : undefined))
  const rankingClientesFidelizados = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.rankingClientesFidelizados : undefined) : undefined))
  const totalLojas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.totalLojas : undefined) : undefined))
  const { isLoading } = useSelector((state) => state.dashboard)
  //const percentage = () => {
  //  var total = eventosFuturos + eventosPassados
  //  var currentValue = eventosPassados
  //  var percentage = parseFloat(((currentValue / total) * 100).toFixed(1))

  //  if (isNaN(percentage)) {
  //    return ' '
  //  } else {
  //    return percentage
  //  }
  //}
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL DE CLIENTES
            </Typography>
            <Tooltip title='Total de clientes da loja no app no período do filtro'>
              <Typography variant="h3">
                {isLoading ? (
                  <Skeleton />
                ) : (
                  (isNaN(clientesFidelizados) ? 0 : clientesFidelizados)
                )}
              </Typography>
            </Tooltip>
            <Typography color="inherit" variant="body2">
              NO APP
            </Typography>
            <Tooltip title='Total de clientes em todo o aplicativo'>
              <Typography variant="h3">
                {isLoading ? (
                  <Skeleton />
                ) : (
                  (isNaN(totalClientes) ? 0 : totalClientes)
                )}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon />
            </Avatar>
            <Typography variant="h5" style={{ marginTop: 5 }}>
              {isLoading ? (
                <Skeleton />
              ) : (
                (rankingClientesFidelizados != null ? rankingClientesFidelizados + 'º / ' + totalLojas : '')
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

ClientesChartCard.propTypes = {
  className: PropTypes.string
}

export default ClientesChartCard
