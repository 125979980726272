import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, fornecedorAction, totvsInsumoAction, lojaAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextValidator,
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import SearchIcon from '@material-ui/icons/Search'
import moment from 'moment'
import ComprasCollapsableRow from './comprasCollapsableRow.component'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const useStyles = makeStyles({
  root: {
    '& .a': {
      backgroundColor: '#E8F5E9'
    },
    '& .b': {
      backgroundColor: '#FFFDE7'
    },
    '& .c': {
      backgroundColor: '#FFEBEE'
    },
    '& .rightAlign': {
      textAlign: 'right',
      justifyContent: 'right',
      alignContent: 'right'
    },
    '& .total': {
      fontWeight: 700
    },
    '& .customCursor': {
      cursor: 'pointer'
    }
  },
});

const RelatorioCompras = (props) => {

  const dispatch = useDispatch()
  const { className, campanha, ...rest } = props
  const { relatorio, isLoading } = props.relatorio
  const { totalRows } = relatorio
  const classes = useStyles();

  const queryParameters = new URLSearchParams(window.location.search)
  const id_insumo = queryParameters.get("id_insumo")
  const id_loja = queryParameters.get("id_loja")
  const data_inicio = queryParameters.get("data_inicio")
  const data_fim = queryParameters.get("data_fim")

  const [dataInicio, setDataInicio] = useState(undefined)
  const [dataFim, setDataFim] = useState(undefined)

  const [nomeFornecedor, setNomeFornecedor] = useState('')

  const [valueInsumo, setValueInsumo] = useState(null)
  const [inputValueInsumo, setInputValueInsumo] = useState('')

  const [valueFornecedor, setValueFornecedor] = useState(null)
  const [inputValueFornecedor, setInputValueFornecedor] = useState('')

  const [precoMinimo, setPrecoMinino] = useState(undefined)
  const [precoMaximo, setPrecoMaximo] = useState(undefined)

  const [selectedLojas, setSelectedLojas] = useState([]);

  const [status, setStatus] = useState("");

  const { idLoja } = useSelector(state => state.usuarioLoja)
  const totvsInsumos = useSelector((state) => state.totvsInsumo.totvsInsumo)
  const fornecedores = useSelector((state) => state.fornecedor.fornecedor)

  const optionsInsumos = ((totvsInsumos && totvsInsumos.length) ? totvsInsumos.map(u => ({ id: u.value, nome: u.text })) : []);

  const lojas = useSelector((state) => state.loja.loja)
  const [valueLojas, setValueLojas] = useState([]);

  useEffect(() => {
    dispatch(totvsInsumoAction.getTotvsInsumoSelectComprasProdutos())

    const dataIni = moment().subtract(1, "months")
    const dataFi = moment();
    setDataInicio(moment(dataIni).format("YYYY-MM-DD"));
    setDataFim(moment(dataFi).format("YYYY-MM-DD"));

    dispatch(relatorioAction.clear());
     
    dispatch(totvsInsumoAction.getTotvsInsumoSelect())
    dispatch(fornecedorAction.getFornecedorSelect())
    dispatch(lojaAction.getLojaSelect())
  }, []);

  useEffect(() => {
    setDataInicio(data_inicio)
    setDataFim(data_fim)

    let lojaId = lojas.indexOf(lojas.find((l) => l.value == id_loja))
    let insumo = optionsInsumos.find((i) => i.id == id_insumo)

    let valueLoja = lojas[lojaId]

    setValueLojas(valueLoja ? [valueLoja] : [])
    setValueInsumo(insumo)

  }, [id_insumo, id_loja, data_inicio, data_fim])

  useEffect(() => {


  }, [valueInsumo])

  const handleSubmit = (event) => {
    dispatch(relatorioAction.clear());

    let idLojas = valueLojas.map(l => Number(l.value))

    let payload = {
      DataHoraInicio: moment(dataInicio).format("YYYY-MM-DD"),
      DataHoraFim: moment(dataFim).format("YYYY-MM-DD"),
      NomeFornecedor: nomeFornecedor ?? '',
      IdFornecedor: valueFornecedor ? valueFornecedor.value : null,
      IdTotvsInsumo: valueInsumo ? valueInsumo.id : null,
      ValorUnitarioMinimo: precoMinimo ? precoMinimo : null,
      ValorUnitarioMaximo: precoMaximo ? precoMaximo : null,
      IdLojas: idLojas ? idLojas : [],
      Aprovado: status !== "" ? status: null
    }

    dispatch(relatorioAction.getRelatorioCompras(payload))
  }

  const handleChangeDataInicio = (e) => {
    setDataInicio(e.target.value)
  }

  const handleChangeDataFim = (e) => {
    setDataFim(e.target.value)
  }

  const handleChangeNomeFornecedor = (e) => {
    setNomeFornecedor(e.target.value)
  }

  const handleChangePrecoMinimo = (e) => {
    setPrecoMinino(e.target.value)
  }

  const handleChangePrecoMaximo = (e) => {
    setPrecoMaximo(e.target.value)
  }

  const handleChangeLojas = (value) => {
    setValueLojas(value);
  };

  const handleChangeStatus = (value) => {
    setStatus(value)
  }

  const handleClean = () => {
    dispatch(relatorioAction.clear());
  }

  return (
    <Page className={classes.root} title="Relatório Compras">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioCompras"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Compras'}
                    />
                    <Grid container spacing={2} style={{ alignItems: 'center' }}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Início *"
                          type="date"
                          value={dataInicio}
                          onChange={handleChangeDataInicio}
                          id="dataInicio"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Término *"
                          type="date"
                          value={dataFim}
                          onChange={handleChangeDataFim}
                          id="dataInicio"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={6}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Preço Mínimo"
                          type="number"
                          value={precoMinimo}
                          onChange={handleChangePrecoMinimo}
                          id="precoMinimo"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={6}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Preço Máximo"
                          type="number"
                          value={precoMaximo}
                          onChange={handleChangePrecoMaximo}
                          id="precoMaximo"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingBottom: "15px" }}>
                        <TextValidator
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Nome Fornecedor"
                          value={nomeFornecedor}
                          onChange={handleChangeNomeFornecedor}
                          id="nomeFornecedor"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <Autocomplete
                          value={valueFornecedor}
                          onChange={(event, newValue) => {
                            setValueFornecedor(newValue);
                          }}
                          inputValue={inputValueFornecedor}
                          onInputChange={(event, newInputValue) => {
                            setInputValueFornecedor(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={fornecedores}
                          defaultValue={null}
                          getOptionLabel={option => option.text}
                          
                          renderInput={(params) => <TextField {...params} label="SELECIONE UM FORNECEDOR..." fullWidth variant="outlined" />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <Autocomplete
                          value={valueInsumo}
                          onChange={(event, newValue) => {
                            setValueInsumo(newValue);
                          }}
                          inputValue={inputValueInsumo}
                          onInputChange={(event, newInputValue) => {
                            setInputValueInsumo(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={optionsInsumos}
                          defaultValue={null}
                          getOptionLabel={option => option.nome}
                          renderInput={(params) => <TextField {...params} label="SELECIONE UM INSUMO..." fullWidth variant="outlined" />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={10} md={10} xl={10} xs={12} style={{ paddingBottom: "15px" }}>
                        <Autocomplete
                          multiple
                          id="lojasSelecionadas"
                          value={valueLojas}
                          options={lojas}
                          getOptionLabel={(option) => option.text}
                          onChange={(event, value) => handleChangeLojas(value)}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="SELECIONE LOJAS..."
                            />
                          )}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={12} style={{ paddingBottom: "15px" }}>
                        <SelectValidator
                          id="statusProdutos"
                          value={status}
                          onChange={(event) => handleChangeStatus(event.target.value)}
                          variant="outlined"
                          label="Status"
                          inputProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">
                            <em>Todos</em>
                          </MenuItem>
                          <MenuItem value={true}>Aprovado</MenuItem>
                          <MenuItem value={false}>Reprovado</MenuItem>
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlign: 'right',
                        justifyContent: 'right',
                        gap: 10
                      }}>
                        <Button
                          color="default"
                          onClick={() => handleClean()}
                          variant="contained"
                        >
                          Limpar
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={props.relatorio.isLoading}
                          startIcon={<SearchIcon />}
                          endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                        >
                          {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
                  <Table>
                    <TableHead>
                      {totalRows > 0 &&
                        <TableRow>
                          <TableCell align="center" colSpan={6}>
                            Foram encontrados {totalRows} registros correspondentes
                          </TableCell>
                        </TableRow>
                      }
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Nome Fornecedor</TableCell>
                        <TableCell>Fornecedor</TableCell>
                        <TableCell>Loja</TableCell>
                        <TableCell key="collapseIcon"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((r) => <ComprasCollapsableRow row={r} idLoja={idLoja} />)
                        :
                        (isLoading ?
                          Array.apply(null, { length: 6 }).map((e, i) => (
                            <TableRow hover>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Skeleton animation="wave" />
                              </TableCell>
                            </TableRow>
                          ))
                          :
                          <TableRow>
                            <TableCell rowSpan={6} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioCompras.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioComprasPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioCompras))
)
export { connectedRelatorioComprasPage as RelatorioCompras }
