import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from 'material-ui-confirm'
import { toast } from 'react-toastify'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Input,
  MenuItem,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { fornecedorAction, fornecedorFilialAction, lojaAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils, cnpjMask } from '../../utils'
import SaveIcon from '@material-ui/icons/Save'
import { EditorHeader, Page } from 'components'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const FornecedorEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, fornecedor, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [idLoja, setIdLoja] = useState(0);
  const [textLoja, setTextLoja] = useState('');
  const [vincularLojas, setVincularLojas] = useState(false);

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const lojas = useSelector((state) => state.loja.loja)
  const confirm = useConfirm()

  useEffect(() => {
    if (params.id) {
      dispatch(fornecedorAction.getFornecedorById(params.id))
    } else {
      dispatch(fornecedorAction.clear())
      dispatch(fornecedorAction.clearAll())
    }

    dispatch(lojaAction.getLojaSelect())

  }, []);

  useEffect(() => {

  }, [ready, props.fornecedor.isLoading])

  useEffect(() => {
    if (props.fornecedor.todasLojas) {
      setVincularLojas(false);
      props.fornecedor.fornecedoresLojas = []
    } else {
      setVincularLojas(true);
    }

  }, [props.fornecedor.todasLojas])

  const handleChange = (prop) => (event) => {
    dispatch(fornecedorAction.onChangeProps(prop, event))
  }

  const handleSelectChangeState = prop => event => {
    setIdLoja(event.target.value);
    setTextLoja(event.nativeEvent.target.innerText);
  };

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.fornecedor.nome,
      email: props.fornecedor.email,
      integracao: props.fornecedor.integracao,
      integracaoArquivo: props.fornecedor.integracaoArquivo,
      tipoIntegracaoAPI: props.fornecedor.tipoIntegracaoAPI,
      versaoIntegracaoAPI: props.fornecedor.versaoIntegracaoAPI,
      chaveIntegracaoAPI: props.fornecedor.chaveIntegracaoAPI,
      todasLojas: props.fornecedor.todasLojas ? props.fornecedor.todasLojas : false,
      fornecedoresLojas: !props.fornecedor.todasLojas ? props.fornecedor.fornecedoresLojas : []
    }

    if (params.id) {
      dispatch(fornecedorAction.editFornecedorInfo(params.id, payload, true))
    } else {
      dispatch(fornecedorAction.createFornecedor(payload))
    }
  };

  const handleSubmitApiKey = (event) => {
    let payload = {
      tipoIntegracaoAPI: props.fornecedor.tipoIntegracaoAPI,
      versaoIntegracaoAPI: props.fornecedor.versaoIntegracaoAPI,
      chaveIntegracaoAPI: props.fornecedor.chaveIntegracaoAPI
    }

    if (params.id) {
      dispatch(fornecedorAction.editFornecedorApiKeyInfo(params.id, payload, true))
    }
  };

  const handleAddOnList = e => {

    if (props.fornecedor.fornecedoresLojas.filter(ts => ts.idLoja == parseInt(idLoja)).length > 0) {
      toast.error('Erro ao adicionar! Esta loja já existe!')
    }
    else {

      if (idLoja != '' && idLoja != '0') {
        props.fornecedor.fornecedoresLojas.push({ 'idLoja': idLoja, 'idFornecedor': props.fornecedor.id, 'loja': { 'nome': textLoja } });
        forceUpdate(n => !n);
        setIdLoja(0);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos uma loja!</div>'
        });
      }
    }
  };

  const handleRemoveOnList = id => {

    props.fornecedor.fornecedoresLojas = props.fornecedor.fornecedoresLojas.filter(ts => ts.idLoja != parseInt(id));
    forceUpdate(n => !n);
  };

  function excludeFilter(obj) {
    if (props.fornecedor.fornecedoresLojas && props.fornecedor.fornecedoresLojas.length > 0) {
      if (props.fornecedor.fornecedoresLojas.filter(fl => fl.idLoja == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  const handleDeleteFornecedorFilial = (id) => {
    confirm({
      title: 'Você deseja desvincular este fornecedor filial?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      disabled: props.fornecedor.isLoading,
      confirmationText: props.fornecedor.isLoading ? 'Excluindo fornecedor filial...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(fornecedorFilialAction.deleteFornecedorFilialById(id, params.id))
    })
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Novo Fornecedor'}</b>
      </Typography>
    )
  }

  function EditTextApiKey() {
    return (
      <Typography variant="h4">
        <b>{'Integração'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Fornecedores - Editar Fornecedor'
          : 'Gerência de Fornecedores - Novo Fornecedor'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formFornecedor"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Fornecedores"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Fornecedor' tabela='Fornecedor' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.fornecedor.nome}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="E-mail"
                        value={props.fornecedor.email}
                        className={classes.textField}
                        onChange={handleChange('email')}
                        id="email"
                        name="email"
                        autoComplete="email"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={2}>
                      <CardContent>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.fornecedor.integracao}
                              onChange={handleChange('integracao')}
                              color="primary"
                            />
                          }
                          label="Integração"
                        />
                      </CardContent>
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={2}>
                      <CardContent>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={props.fornecedor.integracaoArquivo}
                              onChange={handleChange('integracaoArquivo')}
                              color="primary"
                            />
                          }
                          label="Integração Arquivo"
                        />
                      </CardContent>
                    </Grid>                    
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '20px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ alignContent: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5" align="center">
                      <b>{'Lojas vinculadas'}</b>
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.fornecedor.todasLojas}
                          onChange={handleChange('todasLojas')}
                          color="primary"
                        />
                      }
                      label="Selecionar todas as lojas"
                    />
                  </Grid>
                  <Grid container xl={12} lg={12} md={12} xs={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        disabled={!vincularLojas}
                        id="idLoja"
                        label="Lojas vinculadas *"
                        value={idLoja}
                        onChange={handleSelectChangeState('idLoja')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idLoja"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Loja *</em>
                        </MenuItem>
                        {!isEmpty(lojas) &&
                          undefined !== lojas &&
                          lojas.length &&
                          lojas.filter(excludeFilter).map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center' }}>
                      <Button style={{ width: '200px', marginLeft: '15px' }} disabled={!vincularLojas} name="btnLojas" id="btnLojas" className="btn" variant="contained" color="primary" component='a' onClick={handleAddOnList}>Adicionar&nbsp;<FontAwesomeIcon icon={faPlus} /></Button>
                    </Grid>
                    <Grid
                      item
                      lg={vincularLojas ? 4 : 12}
                      md={vincularLojas ? 4 : 12}
                      xl={vincularLojas ? 4 : 12}
                      xs={12}
                    >
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                        <Grid
                          item
                          lg={vincularLojas ? 12 : 4}
                          md={vincularLojas ? 12 : 4}
                          xl={vincularLojas ? 12 : 4}
                          xs={12}
                        >
                          {(props.fornecedor && props.fornecedor.fornecedoresLojas && props.fornecedor.fornecedoresLojas.length > 0 ?
                            props.fornecedor.fornecedoresLojas.map(n => {
                              return (
                                (n.id !== '' ?
                                  <Button color="default" onClick={() => handleRemoveOnList(n.idLoja)} key={'button' + n.id} disabled={!vincularLojas}>
                                    <Badge color="primary" style={{ fontSize: 15, paddingLeft: 7, paddingRight: 7 }}>
                                      {(n.loja ? n.loja.nome : n.idLoja)}
                                    </Badge>
                                    &nbsp;<FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                  : null)
                              );
                            })
                            :
                            null
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} style={{ marginTop: '20px', marginBottom: '25px' }}>
                    <Divider />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ alignContent: 'center', justifyContent: 'center' }}>
                    <Typography variant="h5" align="center">
                      <b>{'Fornecedores filiais vinculados'}</b>
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} align="center" style={{ marginTop: '15px', marginBottom: '20px' }}>
                    <Card>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>CNPJ</TableCell>
                              <TableCell>Ação</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              (props.fornecedor.fornecedoresFiliais != null && props.fornecedor.fornecedoresFiliais.length > 0 ?
                                props.fornecedor.fornecedoresFiliais.map(n => {
                                  return (
                                    (n.id !== '' ?
                                      <TableRow hover >
                                        <TableCell lg={12} md={12} xl={12} xs={12}
                                          style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                          {n.cnpj ? cnpjMask(n.cnpj) : ''}
                                        </TableCell>
                                        <TableCell lg={12} md={12} xl={12} xs={12}
                                          style={{ justifyContent: 'center', alignContent: 'bottom', alignItems: 'center' }}>
                                          <Tooltip title="Excluir">
                                            <IconButton onClick={() => handleDeleteFornecedorFilial(n.id)}>
                                              <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '15px' }} />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                      : null)
                                  )
                                })
                                : <TableRow>
                                  <TableCell colSpan={2}>
                                    <Typography align="center" variant="subtitle2">
                                      Nenhum fornecedor filial vinculado até o momento
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/Fornecedores')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.fornecedor.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.fornecedor.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.fornecedor.isLoading ? 'Atualizando fornecedor...' : 'Atualizar') : (props.fornecedor.isLoading ? 'Salvando fornecedor...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {(params.id && props.fornecedor.integracao ?
        <Grid
          {...rest}
          className={clsx(classes.root, className)}
          container
          spacing={3}
        >
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Grid item md={12} xs={12}>
              <Card>
                <ValidatorForm
                  className={classes.form}
                  ref={formRef}
                  id="formFornecedor"
                  onSubmit={(event) => handleSubmitApiKey(event)}
                >
                  <CardHeader
                    subheader="Editar Tipo e Chave da API"
                    title={<EditTextApiKey />}
                  />
                  <Divider />
                  <CardContent >
                    <Grid container spacing={2}>
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <SelectValidator
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            className={classes.textField}
                            label="Tipo de Integração"
                            labelId="tipoIntegracaoAPI"
                            value={props.fornecedor.tipoIntegracaoAPI}
                            onChange={handleChange('tipoIntegracaoAPI')}
                            name="tipoIntegracaoAPI"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              name: "TipoIntegracaoAPI",
                              id: "tipoIntegracaoAPI"
                            }}
                            input={<Input id="tipoIntegracaoAPI" />}
                            margin="normal"
                            variant="outlined"
                            id="tipoIntegracaoAPI"
                          >
                            <MenuItem disabled value={''}>Tipo Integração</MenuItem>
                            <MenuItem value={'BLING'}>BLING</MenuItem>
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <SelectValidator
                            //validators={['required']}
                            //errorMessages={['Campo obrigatório!']}
                            className={classes.textField}
                            label="Versão"
                            labelId="versaoIntegracaoAPI"
                            value={props.fornecedor.versaoIntegracaoAPI}
                            onChange={handleChange('versaoIntegracaoAPI')}
                            name="versaoIntegracaoAPI"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              name: "VersaoIntegracaoAPI",
                              id: "versaoIntegracaoAPI"
                            }}
                            input={<Input id="versaoIntegracaoAPI" />}
                            margin="normal"
                            variant="outlined"
                            id="versaoIntegracaoAPI"
                          >
                            <MenuItem disabled value={''}>Versão</MenuItem>
                            <MenuItem value={'v2'}>v2</MenuItem>
                            <MenuItem value={'v3'}>v3</MenuItem>
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Chave API"
                            value={props.fornecedor.chaveIntegracaoAPI}
                            className={classes.textField}
                            onChange={handleChange('chaveIntegracaoAPI')}
                            id="chaveIntegracaoAPI"
                            name="chaveIntegracaoAPI"
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                        * Campos obrigatórios
                      </Typography>

                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      display: 'flex',
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlgin: 'right',
                      justifyContent: 'right'
                    }}>
                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={props.fornecedor.isLoading}
                        startIcon={<SaveIcon />}
                        endIcon={props.fornecedor.isLoading && <CircularProgress size={24} />}
                      >
                        {params.id ? (props.fornecedor.isLoading ? 'Atualizando fornecedor...' : 'Atualizar') : (props.fornecedor.isLoading ? 'Salvando fornecedor...' : 'Salvar')}
                      </Button>
                    </Grid>
                  </CardActions>
                </ValidatorForm>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        :
        null
      )}


    </Page>
  )
}

FornecedorEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedFornecedorEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(FornecedorEdit))
)
export { connectedFornecedorEditPage as FornecedorEdit }
