import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, totvsInsumoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  withStyles,
  Grid,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'; //componente da tabela sorteável
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { toast } from 'react-toastify'
import moment from 'moment'
import { history } from 'utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const useStyles = makeStyles({
  root: {
    '& .a': {
      backgroundColor: '#E8F5E9'
    },
    '& .b': {
      backgroundColor: '#FFFDE7'
    },
    '& .c': {
      backgroundColor: '#FFEBEE'
    },
    '& .rightAlign': {
      textAlign: 'right',
      justifyContent: 'right',
      alignContent: 'right'
    },
    '& .total': {
      fontWeight: 700
    },
    '& .customCursor': {
      cursor: 'pointer'
    }
  },
});

const DataGridTotal = ({ rowTotal, columns }) => {
  return (
    <DataGrid
      rows={rowTotal && rowTotal.length > 0 ? rowTotal : []}
      columns={columns}
      autoHeight
      hideFooter
      getCellClassName={() => {
        return 'total';
      }}
      disableSelectionOnClick
      disableColumnSelector
      headerHeight={0}
      components={{
        Toolbar: () => null,
      }}
      pagination={false}
      autoPageSize
      pageSize={1}
      rowsPerPageOptions={[1]}
    />
  );
};

const RelatorioCPVABC = (props) => {

  const dispatch = useDispatch()
  const { className, ...rest } = props
  const { relatorio } = props.relatorio
  const classes = useStyles();

  const [dataInicio, setDataInicio] = useState(undefined)
  const [dataFim, setDataFim] = useState(undefined)
  const [updatedRows, setUpdatedRows] = useState([])
  const [rowTotal, setRowTotal] = useState([])

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const [noEstado, setNoEstado] = useState(!!idLoja);
  const [menorPreco, setMenorPreco] = useState(true);

  useEffect(() => {
    const dataIni = moment().subtract(1, "months")
    const dataFi = moment();
    setDataInicio(moment(dataIni).format("YYYY-MM-DD"));
    setDataFim(moment(dataFi).format("YYYY-MM-DD"));

    dispatch(totvsInsumoAction.getTotvsInsumoSelect());
    dispatch(relatorioAction.clear());

  }, []);

  const handleSubmit = (event) => {

    dispatch(relatorioAction.clear());

    if (dataInicio == undefined) {
      toast.error('Informe uma Data de Início!')
    }

    if (dataFim == undefined) {
      toast.error('Informe uma Data Final!')
    }

    if (dataFim < dataInicio) {
      toast.error('Data Final deve ser maior que a Data de Início!')
    }

    let payload = {
      IdLoja: idLoja ? idLoja : 0,
      DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      DataFim: moment(dataFim).format("YYYY-MM-DD"),
      NoEstado: noEstado || false,
      MenorPreco: menorPreco || false,
    };
    dispatch(relatorioAction.getRelatorioCPVABC(payload, true));
  };

  useEffect(() => {
    if (relatorio && relatorio.length > 0) {
      getValorTotal()
    } else {
      setUpdatedRows([]);
    }
  }, [relatorio])

  // Use map to populate the rows
  let rows: GridRowsProp = relatorio && relatorio.length > 0 && relatorio?.map(n => ({
    id: n.idTotvsInsumo != null ? n.idTotvsInsumo : 0,
    col1: n.curva,
    col2: n.totvsInsumo,
    col3: n.valorTotal ? n.valorTotal : 0,
    col4: n.percentualCpv ? n.percentualCpv : 0,
    col5: n.valorTotal && relatorio && getTotalSemFormatacao() ? (n.valorTotal * 100 / getTotalSemFormatacao()) : 0,
    col6: (n.oportunidade ? n.oportunidade : 0),
  }));

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Curva', flex: 1, disableColumnMenu: true, filterable: false, filterOperators: false, hideSortIcons: true, sortable: false, disableReorder: true },
    { field: 'col2', headerName: 'Unidade de Insumo', flex: 2, disableColumnMenu: true, filterable: false, hideSortIcons: true, sortable: false, },
    {
      field: 'col3', headerName: 'Valor (R$)', flex: 1, disableColumnMenu: true, filterable: false, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params) => {
        return params.value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      }
    },
    {
      field: 'col4', headerName: '% do CPV', flex: 1, disableColumnMenu: true, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params) => {
        return params.value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      }
    },
    {
      field: 'col5', headerName: '% do Total', flex: 1, disableColumnMenu: true, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params) => {
        return params.value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      }
    },
    { field: 'col6', headerName: 'Oportunidade (R$)', flex: 1, disableColumnMenu: true, align: 'right', headerAlign: 'right', type: 'number', valueFormatter: (params) => {
      return params.value.toLocaleString('pt-br', { minimumFractionDigits: 2 })
    }
    }
  ];
  function getValorTotal() {

    if (relatorio && relatorio.length > 0) {
      const totalRow = {
        id: 'total',
        col1: 'TOTAL',
        col2: '',
        col3: 'R$ ' + (relatorio?.reduce(function (acumulador, valorAtual) {
          return acumulador + valorAtual.valorTotal;
        }, 0)).toLocaleString('pt-br', { minimumFractionDigits: 2 }),
        col4: getTotalCpv().toLocaleString('pt-br', { minimumFractionDigits: 2 }) + '%',
        col5: '100,00%',
        col6: 'R$ ' +  getOportunidadeTotal().toLocaleString('pt-br', { minimumFractionDigits: 2 })
      };

      setUpdatedRows([...rows]);
      setRowTotal([...rowTotal, totalRow]);
    }
  }


  function getTotalSemFormatacao() {
    if (relatorio && relatorio.length > 0) {
      let total = relatorio.reduce(function (acumulador, valorAtual) {
        return acumulador + valorAtual.valorTotal;
      }, 0)
      return total
    } else {
      return 0
    }
  }

  const customLocaleText = {
    noRowsLabel: 'Não há conteúdo para exibir'
  };

  const handleGoToDashboardCompras = (id) => {
    if (id) {
      window.open(`/dashboardCompras?id=${id}&dataInicio=${dataInicio}&dataFim=${dataFim}`, '_blank');
    }
  }

  const handleChangeDataInicio = (e) => {
    setDataInicio(e.target.value)
  }

  const handleChangeDataFim = (e) => {
    setDataFim(e.target.value)
  }

  const handleChangeNoEstado = (e) => {
    setNoEstado(e.target.checked)
  }

  const handleChangeMenorPreco = (e) => {
    setMenorPreco(e.target.checked)
  }
  function getTotalCpv() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.reduce(function (acumulador, valorAtual) {
        return acumulador + valorAtual.percentualCpv;
      }, 0)

      return total;

    } else {
      return (0.00);
    }
  }

  function getOportunidadeTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.reduce(function (acumulador, valorAtual) {
        return acumulador + valorAtual.oportunidade;
      }, 0)

      return total;

    } else {
      return (0.00);
    }
  }

  return (
    <Page className={classes.root} title="Relatório Curva ABC">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioCPVABC"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Curva ABC'}
                    />
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Início *"
                          type="date"
                          value={dataInicio}
                          onChange={handleChangeDataInicio}
                          id="dataInicio"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Final *"
                          type="date"
                          value={dataFim}
                          onChange={handleChangeDataFim}
                          id="dataFim"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                    </Grid>
                    <Grid item lg={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={noEstado}
                            onChange={handleChangeNoEstado}
                            color="primary"
                            disabled={!idLoja}
                          />
                        }
                        label="No meu estado"
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={menorPreco}
                            onChange={handleChangeMenorPreco}
                            color="primary"
                          />
                        }
                        label="Menor preço médio"
                      />
                    </Grid>
                    {/*<Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>*/}
                      <Grid item xl={12} lg={12} md={12} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </Button>
                        </Grid>
                        {/*<Grid>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handlePrint()}
                            variant="outlined"
                            startIcon={<PrintIcon />}
                          >
                            Imprimir
                          </Button>
                        </Grid>*/}
                      </Grid>
                    {/*</Grid>*/}
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                {/*<TableContainer component={Paper}>*/}
                {/*  <Table>*/}
                {/*    <TableHead>*/}
                {/*      <TableRow>*/}
                {/*        <TableCell>Curva</TableCell>*/}
                {/*        <TableCell>Unidade de Insumo</TableCell>*/}
                {/*        <TableCell align="right">Valor</TableCell>*/}
                {/*        <TableCell align="right">% do CPV</TableCell>*/}
                {/*        <TableCell align="right">% Sobre o Total</TableCell>*/}
                {/*      </TableRow>*/}
                {/*    </TableHead>*/}
                {/*    <TableBody>*/}
                {/*      {undefined !== relatorio && relatorio.length*/}
                {/*        ? relatorio.map((n) => {*/}
                {/*          let total = relatorio.reduce(function (acumulador, valorAtual) {*/}
                {/*            return acumulador + valorAtual.valorTotal;*/}
                {/*          }, 0);*/}

                {/*          return (*/}

                {/*            <TableRow hover key={n.idTotvsInsumoUnidade}>*/}
                {/*              <TableCell component="th" scope="row" style={{ 'backgroundColor': (n.curva == 'A' ? '#E8F5E9' : (n.curva == 'B' ? '#FFFDE7' : (n.curva == 'C' ? '#FFEBEE' : '#FFFFFF'))) }}>*/}
                {/*                {n.curva}*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row" style={{ 'backgroundColor': (n.curva == 'A' ? '#E8F5E9' : (n.curva == 'B' ? '#FFFDE7' : (n.curva == 'C' ? '#FFEBEE' : '#FFFFFF'))) }}>*/}
                {/*                <Link onClick={() => handleGoToDashboardCompras(n.idTotvsInsumoUnidade)} style={n.idTotvsInsumoUnidade && { cursor: 'pointer' }}>*/}
                {/*                  {n.totvsInsumoUnidade}*/}
                {/*                </Link>*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row" align="right" style={{ 'backgroundColor': (n.curva == 'A' ? '#E8F5E9' : (n.curva == 'B' ? '#FFFDE7' : (n.curva == 'C' ? '#FFEBEE' : '#FFFFFF'))) }}>*/}
                {/*                {n.valorTotal ? 'R$ ' + n.valorTotal.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row" align="right" style={{ 'backgroundColor': (n.curva == 'A' ? '#E8F5E9' : (n.curva == 'B' ? '#FFFDE7' : (n.curva == 'C' ? '#FFEBEE' : '#FFFFFF'))) }}>*/}
                {/*                {n.percentualCpv ? n.percentualCpv.toLocaleString('pt-br', { minimumFractionDigits: 2 }) + '%' : 0}*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row" align="right" style={{ 'backgroundColor': (n.curva == 'A' ? '#E8F5E9' : (n.curva == 'B' ? '#FFFDE7' : (n.curva == 'C' ? '#FFEBEE' : '#FFFFFF'))) }}>*/}
                {/*                {n.valorTotal && relatorio && total ? (n.valorTotal * 100 / total).toFixed(2) + '%' : 0}*/}
                {/*              </TableCell>*/}
                {/*              */}{/*<TableCell component="th" scope="row" style={{ 'color': (n.curva == 'A' ? '#9CCC65' : (n.curva == 'B' ? '#FFEE58' : (n.curva == 'C' ? '#EF5350' : '#FFFFFF'))) }} align="center">*/}{/*
                */}{/*                <b>{n.curva}</b>*/}{/*
                */}{/*              </TableCell>*/}{/*
                */}{/*              <TableCell component="th" scope="row">*/}{/*
                */}{/*                {n.totvsInsumoUnidade}*/}{/*
                */}{/*              </TableCell>*/}{/*
                */}{/*              <TableCell component="th" scope="row" align="right">*/}{/*
                */}{/*                {n.percentualCpv ? n.percentualCpv.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}*/}{/*
                */}{/*              </TableCell>*/}
                {/*            </TableRow>*/}
                {/*          )*/}
                {/*        })*/}
                {/*        :*/}
                {/*        (isLoading ?*/}
                {/*          Array.apply(null, { length: 6 }).map((e, i) => (*/}
                {/*            <TableRow hover>*/}
                {/*              <TableCell component="th" scope="row">*/}
                {/*                <Skeleton animation="wave" />*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row">*/}
                {/*                <Skeleton animation="wave" />*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row" align="right">*/}
                {/*                <Skeleton animation="wave" />*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row" align="right">*/}
                {/*                <Skeleton animation="wave" />*/}
                {/*              </TableCell>*/}
                {/*              <TableCell component="th" scope="row" align="right">*/}
                {/*                <Skeleton animation="wave" />*/}
                {/*              </TableCell>*/}
                {/*            </TableRow>*/}
                {/*          ))*/}
                {/*          :*/}
                {/*          <TableRow>*/}
                {/*            <TableCell rowSpan={3} colSpan={3} align="center">Nenhum registro encontrado</TableCell>*/}
                {/*          </TableRow>*/}
                {/*        )*/}
                {/*      }*/}
                {/*      {undefined !== relatorio && relatorio.length*/}
                {/*        ?*/}
                {/*        <TableRow>*/}
                {/*          <TableCell rowSpan={3} colSpan={2}><b>Total</b></TableCell>*/}
                {/*          <TableCell rowSpan={3} align="right"><b>{getTotal()}</b></TableCell>*/}
                {/*          <TableCell rowSpan={3} align="right"><b>{getTotalCpv()}</b></TableCell>*/}
                {/*          <TableCell rowSpan={3} align="right"><b>100%</b></TableCell>*/}
                {/*        </TableRow>*/}
                {/*        :*/}
                {/*        null*/}
                {/*      }*/}
                {/*    </TableBody>*/}
                {/*  </Table>*/}
                {/*</TableContainer>*/}

                {/* TABELA COM OS DADOS */}
                <div style={{ height: relatorio?.length <= 0 ? 160 : '100%', width: '100%' }}>
                  <DataGridTotal rowTotal={rowTotal} columns={columns} />
                  <DataGrid
                    sortingOrder={['asc', 'desc']}
                    rows={updatedRows && updatedRows.length > 0 ? updatedRows : []}
                    columns={columns}
                    localeText={customLocaleText}
                    getCellClassName={(params) => {
                      let cellClass = '';
                      if (params.row.col1 === 'A') {
                        cellClass = 'a';
                      } else if (params.row.col1 === 'B') {
                        cellClass = 'b';
                      } else if (params.row.col1 === 'C') {
                        cellClass = 'c';
                      }
                      if (params.field === 'col4' || params.field === 'col5') {
                        cellClass += ' rightAlign';
                      }
                      if (params.id === 'total') {
                        cellClass += ' total';
                      }
                      if (params.id && params.id !== 0 && params.id !== 'total' && params.field == 'col2') {
                        cellClass += ' customCursor';
                      }
                      return cellClass;
                    }}
                    autoHeight
                    disableSelectionOnClick
                    autoPageSize
                    pagination={false}
                    hideFooter //esconde o footer(paginação)
                    onCellClick={(params, event) => {
                      event.defaultMuiPrevented = true;
                      if (params.id && params.id != 0 && params.id != 'total') {
                        handleGoToDashboardCompras(params.id)
                      }
                    }}
                  />
                  <DataGridTotal rowTotal={rowTotal} columns={columns} />
                </div>
              </Card>
            </Grid>
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioCPVABC.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioCPVABCPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioCPVABC))
)
export { connectedRelatorioCPVABCPage as RelatorioCPVABC }
