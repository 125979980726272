/// <reference path="../../_reducers/notificacao.reducer.js" />
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { clienteAction, usuarioAction } from '../../_actions'
import { phoneMask, history } from '../../utils'
import { Avatar } from '@material-ui/core'
import Skeleton from "@material-ui/lab/Skeleton";
import { deepOrange } from '@material-ui/core/colors';
import PropTypes from 'prop-types'
import {
  withStyles,
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Input,
  IconButton,
  Grid,
  TablePagination
} from '@material-ui/core'
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withRouter } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import DeleteIcon from '@material-ui/icons/Delete';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FilterListIcon from '@material-ui/icons/FilterList';
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import moment from 'moment'
import TitleContentCliente from '../TitleContentCliente'
import { useConfirm } from 'material-ui-confirm'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Cliente = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { classes } = props
  const { totalRegistros } = props.cliente
  const { isLoading } = props.cliente
  const { cliente } = props.cliente
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const { page, limit, term, direction, order } = useSelector(state => state.cliente);

  const [openDialog, setOpenDialog] = useState(false);

  const [debounceTerm] = useDebounce(term, 1000)

  const [hasFilter, setHasFilter] = useState(false)
  const [chaveCupomTerm, setChaveCupomTerm] = useState('')

  const [dataInicio, setDataInicio] = useState(null)
  const [dataInicioValue, setDataInicioValue] = useState(null)

  const [dataFim, setDataFim] = useState(null)
  const [dataFimValue, setDataFimValue] = useState(null)

  const [minFidelizacoes, setMinFidelizacoes] = useState('')
  const [maxFidelizacoes, setMaxFidelizacoes] = useState('')

  useEffect(() => {
    handleChangePage(null, page);
  }, [idLoja]);

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm, idLoja]);

  useEffect(() => {
    handleChangePage(null, page);
  }, [idLoja]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeDataInicio = (date) => {
    const formatDate = date ? moment(date).format('yyyy-MM-DD') : null

    setDataInicio(formatDate)
    setDataInicioValue(date)
  };

  const handleChangeDataFim = (date) => {
    const formatDate = date ? moment(date).format('yyyy-MM-DD') : null

    setDataFim(formatDate)
    setDataFimValue(date)
  };

  const handleClearFilters = () => {
    setChaveCupomTerm('')
    setDataInicio(null)
    setDataInicioValue(null)
    setDataFim(null)
    setDataFimValue(null)
    setMinFidelizacoes('')
    setMaxFidelizacoes('')

    setHasFilter(false)
  }

  const handleApplyFilters = () => {
    const containsFilter = chaveCupomTerm || dataInicio || dataFim || minFidelizacoes || maxFidelizacoes

    setHasFilter(containsFilter)

    fetchData()
  }

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      ChaveCupomTerm: chaveCupomTerm || '',
      DataInicio: dataInicio || '',
      DataFim: dataFim || '',
      MinFidelizacoes: minFidelizacoes || 0,
      MaxFidelizacoes: maxFidelizacoes || 0,
      Order: order || 'nome',
      Direction: direction || 'asc',
      IdLoja: (idLoja && idLoja !== '') ? idLoja : 0
    };

    dispatch(clienteAction.getCliente(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(clienteAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(clienteAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.cliente.page = 0;
    dispatch(clienteAction.onChangeProps('term', fakeEvent))
  }

  const handleDeleteUsuarioClienteTelefone = (id) => {
    confirm({
      title: 'Você deseja excluir este cliente?',
      description: 'Essa operação é irreversível',
      disabled: props.usuario.isLoading,
      confirmationText: props.usuario.isLoading ? 'Excluindo cliente...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(usuarioAction.deleteUsuarioById(id))
    }).finally(() => {
      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdLoja: (idLoja && idLoja !== '' ? idLoja : 0)
      };

      dispatch(clienteAction.getCliente(filterModel, true));
    })
  }

  return (
    <Page className={classes.root} title="Clientes">
      <AccessControl
        rule={'clientes.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            <TitleContentCliente
              rule={'clientes.list'}
              subTitle={'GERÊNCIA DE CLIENTES'}
              title={'Lista de Clientes'}
              href={'/insumo'}
            />
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={6} xs={11}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={(event) => handleChangeTerm(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xl={1} lg={1} md={1} xs={1}>
                <Tooltip title="Filtros Avançados">
                  <IconButton className={classes.margin} size="small" onClick={handleOpenDialog}>
                    <Badge color="primary" variant="dot" invisible={!hasFilter}>
                      <FilterListIcon  fontSize="inherit" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ paddingLeft: 64 }}>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Data/Hora Último Acesso</TableCell>
                    <TableCell align="center" colspan={2}>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cliente && null !== cliente && undefined !== cliente && cliente.length !== undefined && cliente.length > 0
                    ? cliente.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row" align="right">
                            {n.usuario && n.usuario.caminhoImagem ?
                              <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Avatar
                                  alt={n.usuario.nome}
                                  className={classes.avatar}
                                  src={n.usuario.caminhoImagem}
                                />
                                <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>  {n.usuario.nome.toUpperCase()} {(n.usuario.sobrenome) ? (n.usuario.sobrenome.toUpperCase()) : ''} </span>
                              </Grid>
                              : n.usuario ?
                                <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <Avatar
                                    sx={{ bgcolor: deepOrange[500] }}
                                    alt={n.usuario.nome}
                                    src="/broken-image.jpg"
                                  />
                                  <span style={{ paddingLeft: 15, verticalAlign: 'center' }}>  {n.usuario.nome.toUpperCase()} {(n.usuario.sobrenome) ? (n.usuario.sobrenome.toUpperCase()) : ''} </span>
                                </Grid>
                                : null
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.usuario ? (n.usuario.email) ? (n.usuario.email.toLowerCase()) : ('') : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.telefone ? phoneMask(n.telefone.slice(-11)) : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.usuario ? (n.usuario.dataHoraUltimoAcesso) ? (moment(n.usuario.dataHoraUltimoAcesso).format("DD/MM/YYYY HH:mm")) : ('') : ''}
                          </TableCell>
                          <TableCell align="right">
                            {(n.telefone ?
                              <AccessControl
                                rule={'clientes.list'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="SendWhatsApp"
                                    size="small"
                                    href={'http://wa.me/' + n.telefone}
                                    target="_blank"
                                  >
                                    <Tooltip title="Enviar mensagem">
                                      <WhatsAppIcon />
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              : null
                            )}
                            <AccessControl
                              rule={'clientes.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Ver perfil"
                                  component="a"
                                  onClick={() => history.push(window.open(`/cliente/${n.id}`))}
                                  size="small"
                                >
                                  <Tooltip title={'Visualizar perfil'}>
                                    <Tooltip >
                                      <VisibilityTwoToneIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'usuarios.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleDeleteUsuarioClienteTelefone(n.usuario.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir cliente">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth aria-modal>
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Typography variant="h4">Filtros Avançados</Typography>

            <IconButton size="small" onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <TextField
                id="chaveCupomTerm"
                label="Chave Cupom"
                value={chaveCupomTerm}
                onChange={(event) => setChaveCupomTerm(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="data-inicio"
                label="Data Início"
                fullWidth
                value={dataInicioValue}
                onChange={handleChangeDataInicio}
                invalidDateMessage="Formato de data inválido"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="data-fim"
                label="Data Fim"
                fullWidth
                value={dataFimValue}
                onChange={handleChangeDataFim}
                invalidDateMessage="Formato de data inválido"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <TextField
                id="min_fidelizacoes"
                label="Mínimo de Fidelizações"
                type="number"
                inputProps={{
                  min: 0,
                }}
                value={minFidelizacoes}
                onChange={(event) => setMinFidelizacoes(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <TextField
                id="max_fidelizacoes"
                label="Máximo de Fidelizações"
                type="number"
                inputProps={{
                  min: 0,
                }}
                value={maxFidelizacoes}
                onChange={(event) => setMaxFidelizacoes(event.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClearFilters} color="primary">
            Limpar
          </Button>
          <Button
            onClick={handleApplyFilters}
            variant="contained"
            color="primary"
            disabled={props.cliente.isLoading}
            startIcon={<FilterListIcon />}
            endIcon={props.cliente.isLoading && <CircularProgress size={24} />} 
          >
            Aplicar Filtros
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  )
}
Cliente.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state;
}
const connectedClientePage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Cliente))
)
export { connectedClientePage as Cliente }
