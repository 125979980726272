import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, lojaAction } from '../../_actions'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Card,
  CardContent,
  CircularProgress,
  Button,
  MenuItem,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  withStyles
} from '@material-ui/core'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print'
import { Page } from 'components'
import moment from 'moment'
import { isEmpty } from 'lodash'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const RelatorioDivergenciasFichaTecnica = (props) => {
  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { relatorio, isLoading } = props.relatorio

  const [dataInicio, setDataInicio] = useState(undefined)
  const [dataFim, setDataFim] = useState(undefined)
  const [loja, setLoja] = useState(0)

  const lojas = useSelector(state => state.loja.loja)

  useEffect(() => {
    props.relatorio.isLoading = false;

    dispatch(relatorioAction.clear());
    dispatch(lojaAction.getLojaSelect());
  }, [])

  const handleChangeDataInicio = (e) => {
    setDataInicio(e.target.value)
  }

  const handleChangeDataFim = (e) => {
    setDataFim(e.target.value)
  }

  const handleChangeLoja = (e) => {
    setLoja(e.target.value)
  }

  const handleSubmit = (e) => {
    if (dataInicio != undefined && dataFim != undefined) {
      if (dataInicio <= dataFim) {
        let payload = {
          DataInicio: dataInicio,
          DataFim: dataFim,
          IdLoja: loja || 0
        }

          dispatch(relatorioAction.getRelatorioDivergenciasFichaTecnica(payload))
      }
    } else {
      dispatch(relatorioAction.clear())
    }
  }

  return (
    <Page className={classes.root} title="Relatório - Divergências FT">
      <AccessControl
        rule={'relatorios.admin'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginTop: 0, marginBottom: 10 }}>
                <ValidatorForm
                  id="formRelatorioDivergenciasFichaTecnica"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={"RELATÓRIO"}
                      title={"Divergências Ficha Técnica"}
                    />
                    <Grid container spacing={2} style={{ alignItems: 'center' }}>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Data Início *"
                          type="date"
                          value={dataInicio}
                          onChange={handleChangeDataInicio}
                          id="dataInicio"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Data Fim *"
                          type="date"
                          value={dataFim}
                          onChange={handleChangeDataFim}
                          id="dataFim"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12}>
                        <SelectValidator
                          id="loja"
                          label="Loja *"
                          name="loja"
                          value={loja || ''}
                          onChange={handleChangeLoja}
                          inputProps={{
                            name: "Loja",
                            id: "loja",
                            shrink: true
                          }}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        >
                          <MenuItem disabled value="">
                            <em>Loja *</em>
                          </MenuItem>
                          {!isEmpty(lojas) &&
                            undefined !== lojas &&
                            lojas.length &&
                            lojas.map((row, index) => (
                              <MenuItem key={index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))
                          }
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        flexDirection: 'row',
                        textAlign: 'right',
                        justifyContent: 'right',
                        gap: 10
                      }}>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disabled={props.relatorio.isLoading}
                          startIcon={<SearchIcon />}
                          endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                        >
                          {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Código Produto</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.codigoProduto || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.descricao || ''}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.contagem || ''}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (dataInicio != undefined && dataFim != undefined ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              <TableCell rowSpan={5} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                            </TableRow>
                          )
                          :
                          <TableRow>
                            <TableCell rowSpan={5} colSpan={6} align="center">Informe uma Data de Início e uma Data de Fim</TableCell>
                          </TableRow>
                        )
                      }
                      {undefined !== relatorio && relatorio.length
                        ?
                        <TableRow>
                          <TableCell rowSpan={2} colSpan={2}><b>Total</b></TableCell>
                          <TableCell ><b>{relatorio.length}</b></TableCell>
                        </TableRow>
                        :
                        null
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )}
      >
      </AccessControl>
    </Page>
  )
}

RelatorioDivergenciasFichaTecnica.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const connectedRelatorioDivergenciasFichaTecnicaPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioDivergenciasFichaTecnica))
)

export { connectedRelatorioDivergenciasFichaTecnicaPage as RelatorioDivergenciasFichaTecnica };
