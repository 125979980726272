import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compraAction, fornecedorInfoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  withStyles,
  Checkbox,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Input,
  Grid,
  TablePagination,
  Typography,
  Chip,
  Box,
  Button,
  FormControlLabel
} from '@material-ui/core'
import Skeleton from "@material-ui/lab/Skeleton";
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone'
import RefreshIcon from '@material-ui/icons/Refresh'
import { withRouter } from 'react-router-dom'
import { history, cnpjMask } from '../../utils'
import TitleContentCompra from '../TitleContentCompra'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page, PaginationActions } from 'components'
import { useDebounce } from 'use-debounce';
import moment from 'moment'
import { CompraInfoFornecedorModal } from '..'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  }
})

const Compra = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const { compra } = props.compra
  const { isLoading } = props.compra
  const { totalRegistros } = props.compra

  const { page, limit, term, direction, order, naoClassificadas } = useSelector(state => state.compra);
  const { idLoja } = useSelector(state => state.usuarioLoja)
  const [debounceTerm] = useDebounce(term, 1000);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {

    const fakeEvent = {
      type: 'text',
      target: 'dataHora'
    }
    dispatch(compraAction.onChangeProps('order', fakeEvent))

    const fakeEvent2 = {
      type: 'text',
      target: 'desc'
    }
    dispatch(compraAction.onChangeProps('direction', fakeEvent2))

  }, []);

  useEffect(() => {
    fetchData();
  }, [page, limit, debounceTerm, naoClassificadas]);

  useEffect(() => {
    handleChangePage(null, 0);
  }, [totalRegistros])

  useEffect(() => {
    handleChangePage(null, page);
    fetchData();
  }, [idLoja]);

  const fetchData = () => {
    let filterModel = {
      Page: page || 0,
      Limit: limit || 10,
      Term: debounceTerm || '',
      Order: order || 'dataHora',
      Direction: direction || 'desc',
      IdLoja: (idLoja && idLoja !== '') ? idLoja : 0,
      Checked: naoClassificadas || false
    };

    dispatch(compraAction.getCompra(filterModel, true));
  };

  const handleChange = prop => event => {
    dispatch(compraAction.onChangeProps(prop, event))
  };

  const handleChangePage = (_, value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    dispatch(compraAction.onChangeProps('page', fakeEvent))
  }

  const handleChangeTerm = (value) => {
    const fakeEvent = {
      type: 'text',
      target: {
        value
      }
    }
    props.compra.page = 0;
    dispatch(compraAction.onChangeProps('term', fakeEvent))
  }

  const handleDeleteCompra = (id) => {
    confirm({
      title: 'Você deseja excluir este Compra?',
      description: 'Essa operação é irreversível',
      disabled: props.compra.isLoading,
      confirmationText: props.compra.isLoading ? 'Excluindo Compra...' : 'Sim, excluir!',
    }).then(() => {
      dispatch(compraAction.deleteCompraById(id))
    })
  }

  const handleRefreshComprasProdutos = (id) => {
    dispatch(compraAction.refreshComprasProdutosById(id))
  }

  const handleSearchInfoFornecedor = (cnpj) => {
    if (cnpjMask(cnpj) === props.fornecedorInfo.cnpj) {
      setModal(true)
    } else {
      dispatch(fornecedorInfoAction.getInfoModal(cnpj));
      setModal(true);
    }
  }

  return (
    <Page className={classes.root} title="Compras">
      <AccessControl
        rule={'compras'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {compra && undefined !== compra && compra.length > 0 ? (
              <TitleContentCompra
                rule={'compras.create'}
                length={compra.length}
                subTitle={'GERÊNCIA DE COMPRA'}
                title={'Lista de Compras'}
                href={'/compra'}
              />
            ) : (
              <TitleContentCompra
                rule={'compras.create'}
                length={0}
                subTitle={'GERÊNCIA DE COMPRA'}
                title={'Lista de Compras'}
                href={'/compra'}
              />
            )}
            <Grid container xl={12} lg={12} md={12} xs={12}>
              <Grid item xl={4} lg={4} md={6} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  onChange={(event) => handleChangeTerm(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xl={8} lg={8} md={6} xs={12} align="right">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange('naoClassificadas')}
                      value="naoClassificadas"
                      checked={naoClassificadas}
                      color="primary"
                    />
                  }
                  label="Exibir somente compras não classificadas"
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data/Hora</TableCell>
                    <TableCell>Loja</TableCell>
                    <TableCell>CNPJ Fornecedor</TableCell>
                    <TableCell>Nome Fornecedor</TableCell>
                    <TableCell>Fornecedor</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Itens não classificados</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compra && undefined !== compra && compra.length
                    ? compra.map((n) => {
                      return (
                        <TableRow hover key={n.id} style={{ textDecorationLine: n.cancelado ? 'line-through' : 'none' }}>
                          <TableCell component="th" scope="row">
                            {moment(n.dataHora).format("DD/MM/YYYY HH:mm")}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.lojaNome || ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                              <Typography>{cnpjMask(n.cnpjFornecedor) || ''}</Typography>
                              <Button style={{ width: '24px', minWidth: '24px', padding: 0, borderRadius: '12px' }} onClick={() => handleSearchInfoFornecedor(n.cnpjFornecedor)} >
                                <InfoIcon />
                              </Button>
                            </Box>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.nomeFornecedor || ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {(n.fornecedorNome || '')}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.valor ? 'R$ ' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" >
                            <Grid container justifyContent="center" spacing={1}>
                              <Grid item>
                                {(n.fornecedorNome ? null : <Chip label="Fornecedor" style={{ backgroundColor: '#D2222D', color: "#FFF" }} size="small" />)}
                              </Grid>
                              <Grid item>
                                {(n.produtoNaoClassificado ? <Chip label="Produtos" style={{ backgroundColor: '#D2222D', color: "#FFF" }} size="small" /> : null)}
                              </Grid>
                              <Grid item>
                                {(n.categoriaNaoClassificada ? <Chip label="Categorias" style={{ backgroundColor: '#D2222D', color: "#FFF" }} size="small" /> : null)}
                              </Grid>
                              <Grid item>
                                {(!n.temComprasProdutos ? <Chip label="Aguard. produtos" style={{ color: "#FFF" }} size="small" /> : null)}
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            {(!n.temComprasProdutos ?
                              <AccessControl
                                rule={'compras.edit'} //permissão necessária para acessar conteúdo
                                yes={() => (
                                  <IconButton
                                    aria-label="Refresh"
                                    component="a"
                                    onClick={() => handleRefreshComprasProdutos(n.id)}
                                    size="small"
                                  >
                                    <Tooltip title={'Atualizar compra'}>
                                      <Tooltip >
                                        <RefreshIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                )}
                              />
                              :
                              null)}
                            <AccessControl
                              rule={'compras.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/compra/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Ver'}>
                                    <Tooltip >
                                      <VisibilityTwoToneIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              //permissão para os usuários tipo 2
                              rule={'compras.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleDeleteCompra(n.id)}
                                  size="small"
                                  disabled={n.dataHoraEnvio != null || n.situacao == 'A' || n.situacao == 'E' || n.situacao == 'R'}
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      isLoading ?
                        Array.apply(null, { length: 6 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChange('limit')}
              ActionsComponent={PaginationActions}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
      {modal && <CompraInfoFornecedorModal toggle={toggle} modal={modal} fornecedor={props.fornecedorInfo} />}
    </Page>
  )
}
Compra.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    compra: state.compra,
    fornecedorInfo: state.fornecedorInfo
  }
}
const connectedCompraPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Compra))
)
export { connectedCompraPage as Compra }
