const initialState = {
  isLoading: false,
  totalRegistros: 0,
  totvsInsumoPrecoReferencia: [],
  id: 0,
  idTotvsInsumo: 0,
  idTotvsInsumoUnidade: 0,
  idEstado: 0,
  dataHoraAtualizacao: '',
  preco: 0,
  excluido: false,
}

export function totvsInsumoPrecoReferencia(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TOTVS_INSUMO_PRECO_REFERENCIA':
      return {
        ...state,
        totvsInsumoPrecoReferencia: action.totvsInsumoPrecoReferencia,
        totalRegistros: action.totalRegistros
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: action.id,
        idTotvsInsumo: action.idTotvsInsumo,
        idTotvsInsumoUnidade: action.idTotvsInsumoUnidade,
        idEstado: action.idEstado,
        dataHoraAtualizacao: action.dataHoraAtualizacao,
        preco: action.preco,
        excluido: action.excluido
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_UPDATED':
      return state
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_CLEAR':
      return initialState
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_CLEAR_TOTVS_INSUMO_PRECO_REFERENCIAS_FORNECEDORES':
      return {
        ...state,
        totvsInsumosUnidadesFornecedores: []
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_CLEAR_ALL':
      return {
        ...state,
        totvsInsumoPrecoReferencia: []
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_ISMODALOPEN':
      return {
        ...state,
        isModalOpen: true
      }
    case 'TOTVS_INSUMO_PRECO_REFERENCIA_NOTISMODALOPEN':
      return {
        ...state,
        isModalOpen: false
      }
    default:
      return state
  }
}
