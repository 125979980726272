import React, { useLayoutEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { history } from '../../utils'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Typography,
  withStyles,
  Hidden,
  Grid
} from '@material-ui/core'
import {
  CardModule
} from './components'
import messageTime from 'utils/stringTimeHello'
import { Page } from 'components'
import { withRouter } from 'react-router-dom'
import Lottie from 'react-lottie';
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
import animationData from './components/rocket.json';
import LogoQuiero from '../../img/arte_cafe.png'
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import { AccessControl } from '../../components/Utils/AccessControl'
import { authActions } from '../../_actions'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'center'
  },
  gridCards: {
    //alignItems: 'center',
    //justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  gridCardsCenter: {
    //alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  image: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 50
  },
  pedidosCompra: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    }
  },
  rocket: {
    [theme.breakpoints.down('md')]: {
      height: 200,
      width: 200
    }
  }
});

const Principal = (props) => {

  const { className, classes, type, ...rest } = props
  const { match: { params } } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authentication.user)

  useLayoutEffect(() => {
    dispatch(authActions.refresh())
  }, [])

  return (
    <Page
      className={classes.root}
      title='Quiero Café - Principal'
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography component="h1" gutterBottom variant="h3">
              {messageTime()}, {user.nome.split(' ').slice(0, -1).join(' ')}
            </Typography>
            <br></br>
            <Typography component="h1" gutterBottom variant="h2">
              {'Selecione um módulo'}
            </Typography>
            <Grid container className={classes.gridCards} spacing={2}>
              <Grid container lg={3} sm={6} xl={3} xs={12} spacing={2} style={{ margin: 0 }}>
                <AccessControl
                  rule={'fidelidade'} //permissão necessária para acessar conteúdo
                  yes={() => (
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <CardModule
                        title="FIDELIDADE"
                        subtitle="Acompanhar fidelizações e trocas."
                        icon={<GroupTwoToneIcon />}
                        onClick={() => history.push('/fidelidade/home')}
                      />
                    </Grid>
                  )}
                  no={() => (<></>)}
                />
                <AccessControl
                  rule={'custos'} //permissão necessária para acessar conteúdo
                  yes={() => (
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <CardModule
                        title="CUSTOS"
                        subtitle="Consultar custos de insumos da loja e da rede."
                        icon={<SettingsTwoToneIcon />}
                        onClick={() => history.push('/compras/home')}
                      />
                    </Grid>
                  )}
                  no={() => (<></>)}
                />                
              </Grid>
              <Grid container lg={3} sm={6} xl={3} xs={12} spacing={2} style={{ margin: 0 }}>
                <AccessControl
                  rule={'pedidoCompra'} //permissão necessária para acessar conteúdo
                  yes={() => (
                    <Grid item lg={12} sm={12} xl={12} xs={12} className={classes.pedidosCompra}>
                      <CardModule
                        title="PEDIDOS DE COMPRA"
                        subtitle="Visualizar e realizar pedidos de compra."
                        icon={<ShoppingCartTwoToneIcon />}
                        onClick={() => history.push('/pedidosCompras')}
                      />
                    </Grid>
                  )}
                  no={() => (<></>)}
                />
                <AccessControl
                  rule={'gerencia'} //permissão necessária para acessar conteúdo
                  yes={() => (
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <CardModule
                        title="GERÊNCIA"
                        subtitle="Cadastrar e listar lojas, usuários, entre outros."
                        icon={<SettingsTwoToneIcon />}
                        onClick={() => history.push('/lojas')}
                      />
                    </Grid>
                  )}
                  no={() => (<></>)}
                />
              </Grid>
              <Grid container lg={3} sm={6} xl={3} xs={12} spacing={2} style={{ margin: 0 }}>
                <AccessControl
                  rule={'operacao'} //permissão necessária para acessar conteúdo
                  yes={() => (
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <CardModule
                        title="OPERAÇÃO"
                        subtitle="Visualizar checklists e dados de operação das lojas."
                        icon={<BallotTwoToneIcon />}
                        onClick={() => history.push('/ChecklistsOperacoesCards/categorias')}
                      />
                    </Grid>
                  )}
                  no={() => (<></>)}
                />
              </Grid>
              <Hidden xsDown>
                <Grid container lg={3} sm={6} xl={3} xs={12} spacing={2}>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Lottie
                      options={defaultOptions}
                      height={220}
                      className={classes.rocket}
                      width={220}
                      isClickToPauseDisabled={true}
                    />
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid container lg={12} sm={12} xl={12} xs={12} className={classes.image}>
            <img
              style={{ justifyContent: 'center' }}
              alt="logo_quiero"
              src={LogoQuiero}
            />
          </Grid>
        </Grid>

      </div>
    </Page >
  )
}
Principal.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string
}
const mapStateToProps = (state) => {
  return state
}
const connectedPrincipalPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Principal))
)
export { connectedPrincipalPage as Principal }
