import React, { useEffect, useState, createRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toast } from 'react-toastify'
import getInitials from 'utils/getInitials'
import {
  Backdrop,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import {
  SelectValidator,
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import {
  categoriaInsumoAction,
  fornecedorAction,
  totvsInsumoAction,
  totvsInsumoUnidadeAction,
  unidadeAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { history, stringUtils } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Page, ImageUploader, EditorHeader } from 'components'
import SaveIcon from '@material-ui/icons/Save';
import HelpIcon from '@material-ui/icons/Help'
import { useConfirm } from 'material-ui-confirm'
import { uploadImage } from '_services'
import { errorsUploadImage } from 'utils'
import Swal from 'sweetalert2';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const InsumoUnidadeEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, totvsInsumoUnidade, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = createRef(null);

  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const confirm = useConfirm()
  const [imagem, setImagem] = useState('')
  const [, forceUpdate] = useState(0);

  const [idFornecedor, setIdFornecedor] = useState(0);
  const [textFornecedor, setTextFornecedor] = useState('');

  const [openEdit, setOpenEdit] = useState(false);
  const [selectedFornecedor, setSelectedFornecedor] = useState(null);

  const totvsInsumos = useSelector((state) => state.totvsInsumo.totvsInsumo)
  const categorias = useSelector((state) => state.categoriaInsumo.categoriaInsumo)
  const fornecedores = useSelector((state) => state.fornecedor.fornecedor)

  const unidade = useSelector((state) => state.unidade.unidade)

  useEffect(() => {
    dispatch(categoriaInsumoAction.getCategoriaInsumoSelect())
    dispatch(totvsInsumoAction.getTotvsInsumoSelect())
    dispatch(fornecedorAction.getFornecedorSelect())
    dispatch(unidadeAction.getUnidadeSelect())
    dispatch(totvsInsumoUnidadeAction.clearTotvsInsumoUnidadesFornecedores())

    if (params?.id) {
      dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeById(params.id))
    } else {
      dispatch(totvsInsumoUnidadeAction.clear())
      dispatch(totvsInsumoUnidadeAction.clearTotvsInsumoUnidadesFornecedores())
    }
  }, []);

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...totvsInsumoUnidade,
        caminhoImagem: null
      }
      dispatch(
        totvsInsumoUnidadeAction.editTotvsInsumoUnidadeInfo(totvsInsumoUnidade.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(totvsInsumoUnidadeAction.onChangeProps('caminhoImagem', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...totvsInsumoUnidade,
          caminhoImagem: response.toString()
        }

        if (totvsInsumoUnidade.id !== 0) {
          dispatch(
            totvsInsumoUnidadeAction.editTotvsInsumoUnidadeInfo(totvsInsumoUnidade.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(totvsInsumoUnidadeAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  //Parte para adicionar os fornecedores
  const handleSelectChangeState = prop => event => {
    setIdFornecedor(event.target.value);
    setTextFornecedor(event.nativeEvent.target.innerText);
  };

  const handleAddOnList = e => {
    if (props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores && props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.filter(ts => ts.idFornecedor == parseInt(idFornecedor)).length > 0) {
      toast.error('Erro ao adicionar! Este fornecedor já existe!')
    }
    else {
      const payload = {
        idFornecedor,
        idTotvsInsumoUnidade: props.totvsInsumoUnidade.id,
        fornecedor: { 'nome': textFornecedor },
        disponivel: true
      }

      if (idFornecedor != '' && idFornecedor != '0') {

        if (props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores) {
          props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.push(payload);
        } else {
          props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores = [];
          props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.push(payload);
        }

        forceUpdate(n => !n);
        setIdFornecedor(0);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Erro ao salvar',
          showCloseButton: true,
          showConfirmButton: true,
          html: '<div style="font-size: 14px; text-align: center">Informe ao menos um fornecedor!</div>'
        });
      }
    }
  };

  const handleOpenDialog = (fornecedor) => {
    setSelectedFornecedor(fornecedor)
    setOpenEdit(true);
  };

  const handleCloseDialog = () => {
    setOpenEdit(false);
  };

  const handleEditFornecedor = () => {
    if (selectedFornecedor) {
      const fornecedor = props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.find(ts => ts.idFornecedor === selectedFornecedor.idFornecedor);

      fornecedor.disponivel = !fornecedor.disponivel

      setOpenEdit(false);
    }
  }

  const handleRemoveOnList = (id) => {
    props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores = props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.filter(ts => ts.idFornecedor != parseInt(id));
    forceUpdate(n => !n);
  };

  const handleChange = (prop) => (event) => {
    dispatch(totvsInsumoUnidadeAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    setImagem(totvsInsumoUnidade.caminhoImagem)
  }, [props.totvsInsumoUnidade.caminhoImagem, open]);

  const handleSubmit = (event) => {
    let payload = {
      id: params.id,
      nome: props.totvsInsumoUnidade.nome,
      idTotvsInsumo: (props.totvsInsumoUnidade.idTotvsInsumo != 0 ? props.totvsInsumoUnidade.idTotvsInsumo : null),
      codigoExterno: props.totvsInsumoUnidade.codigoExterno,
      idExterno: props.totvsInsumoUnidade.idExterno,
      caminhoImagem: props.totvsInsumoUnidade.caminhoImagem,
      pesoLiquido: props.totvsInsumoUnidade.pesoLiquido,
      preco: props.totvsInsumoUnidade.preco,
      idUnidade: (props.totvsInsumoUnidade.idUnidade != 0 ? props.totvsInsumoUnidade.idUnidade : null),
      quantidadeUnidade: props.totvsInsumoUnidade.quantidadeUnidade,
      contagem: props.totvsInsumoUnidade.contagem,
      idUnidadeContagem: (props.totvsInsumoUnidade.idUnidadeContagem != 0 ? props.totvsInsumoUnidade.idUnidadeContagem : null),
      quantidadeUnidadeContagem: props.totvsInsumoUnidade.quantidadeUnidadeContagem,
      totvsInsumosUnidadesFornecedores: props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores,
      idCategoriaInsumo: props.totvsInsumoUnidade.idCategoriaInsumo,
      pedidoMinimo: props.totvsInsumoUnidade.pedidoMinimo,
      pedidoMultiplo: (props.totvsInsumoUnidade.pedidoMultiplo != '' ? props.totvsInsumoUnidade.pedidoMultiplo : null),
      idUnidadeIntegracao: (props.totvsInsumoUnidade.idUnidadeIntegracao != 0 ? props.totvsInsumoUnidade.idUnidadeIntegracao : null),
      pesoLiquidoIntegracao: (props.totvsInsumoUnidade.pesoLiquidoIntegracao != 0 ? props.totvsInsumoUnidade.pesoLiquidoIntegracao : null),
      marcaObrigatoria: props.totvsInsumoUnidade.marcaObrigatoria,
      observacoes: props.totvsInsumoUnidade.observacoes
    }

    if (props.totvsInsumoUnidade.pedidoMultiplo == 0 && props.totvsInsumoUnidade.pedidoMultiplo != '') {
      toast.error("O campo \"Múltiplos de\" não pode ter o valor igual a 0")
    }

    else {
      if (params.id) {
        dispatch(totvsInsumoUnidadeAction.editTotvsInsumoUnidadeInfo(params.id, payload, true))
      } else {
        dispatch(totvsInsumoUnidadeAction.createTotvsInsumoUnidade(payload))

      }
    }
  };

  function excludeFilter(obj) {
    if (props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores && props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.length > 0) {
      if (props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.filter(fl => fl.idFornecedor == parseInt(obj.value)).length > 0) {
        return false;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Unidade de Insumo'}</b>
      </Typography>
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Unidades de Insumos - Editar Unidade de Insumo'
          : 'Gerência de Unidades de Insumos - Nova Unidade de Insumo'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={images}
              onChangeImage={onChangeImage}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={500000}
              resolutionWidth={1100}
              resolutionHeight={1100}
              resolutionType={"less"}
              removeImage={removeImage}
              props={totvsInsumoUnidade}
              imagem={imagem}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>

        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formInsumoUnidade"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Unidades de Insumos"
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Unidade de Insumo' tabela='TotvsInsumoUnidade' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12} >
                  <Grid container spacing={2}>
                    <Grid item lg={7} md={7} xl={7} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onChange={handleChange('nome')}
                        value={props.totvsInsumoUnidade.nome || ''}
                        variant="outlined"
                        onInput={stringUtils.toInputUppercase}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={5} md={5} xl={5} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="idCategoriaInsumo"
                        label="Categoria *"
                        value={props.totvsInsumoUnidade.idCategoriaInsumo || ''}
                        onChange={handleChange('idCategoriaInsumo')}
                        margin="normal"
                        inputProps={{
                          name: "Categoria *",
                          id: "categoria",
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idCategoriaInsumo"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      >
                        <MenuItem disabled value="">
                          <em>Categoria *</em>
                        </MenuItem>
                        {!isEmpty(categorias) &&
                          undefined !== categorias &&
                          categorias.length &&
                          categorias.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={6} md={6} xl={6} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="idTotvsInsumo"
                        label="Insumo"
                        value={props.totvsInsumoUnidade.idTotvsInsumo}
                        onChange={handleChange('idTotvsInsumo')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idTotvsInsumo"
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          <em>Insumo *</em>
                        </MenuItem>
                        {!isEmpty(totvsInsumos) &&
                          undefined !== totvsInsumos &&
                          totvsInsumos.length &&
                          totvsInsumos.map((row, index) => (
                            <MenuItem key={index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <Tooltip title="Utilizado somente para fornecedores que possuem integração direta">
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="codigoExterno"
                          label="Código externo"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={handleChange('codigoExterno')}
                          value={props.totvsInsumoUnidade.codigoExterno || ''}
                          variant="outlined"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <Tooltip title="Utilizado somente para fornecedores que possuem integração direta">
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="idExterno"
                          label="ID externo"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={handleChange('idExterno')}
                          value={props.totvsInsumoUnidade.idExterno || ''}
                          variant="outlined"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="preco"
                        label="Preço padrão *"
                        margin="normal"
                        type="number"
                        onChange={handleChange('preco')}
                        value={props.totvsInsumoUnidade.preco}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        inputProps={{
                          step: 0.01
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="marcaObrigatoria"
                        label="Marca Obrigatória"
                        margin="normal"
                        multiline
                        rows={2}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={handleChange('marcaObrigatoria')}
                        value={props.totvsInsumoUnidade.marcaObrigatoria || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="observacoes"
                        label="Observações"
                        margin="normal"
                        multiline
                        rows={2}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={handleChange('observacoes')}
                        value={props.totvsInsumoUnidade.observacoes || ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: '5px', marginBottom: '5px' }}>
                      <Divider />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Grid container>
                        <Grid item lg={12} md={12} xl={12} xs={12} >
                          <Typography color="textPrimary">
                            <b>PEDIDO</b>
                          </Typography>
                        </Grid>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item lg={2} md={2} xl={2} xs={12}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={props.totvsInsumoUnidade.contagem}
                                  onChange={handleChange('contagem')}
                                  color="primary"
                                />
                              }
                              label="Contagem"
                            />
                          </Grid>
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                            <SelectValidator
                              className={classes.textField}
                              validators={['required']}
                              errorMessages={['Campo obrigatório!']}
                              variant="outlined"
                              id="idUnidade"
                              label="Unidade Pedido *"
                              value={props.totvsInsumoUnidade.idUnidade || ''}
                              onChange={handleChange('idUnidade')}
                              disabled={!props.totvsInsumoUnidade.contagem}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              displayEmpty
                              labelId="idUnidade"
                              variant="outlined"
                            >
                              <MenuItem disabled value="">
                                <em>Unidade Pedido *</em>
                              </MenuItem>
                              {!isEmpty(unidade) &&
                                undefined !== unidade &&
                                unidade.length &&
                                unidade.map((row, index) => (
                                  <MenuItem key={index} value={row.value}>
                                    {row.text}
                                  </MenuItem>
                                ))}
                            </SelectValidator>
                          </Grid>
                          <Grid item lg={4} md={4} xl={4} xs={12}>
                            <TextValidator
                              className={classes.textField}
                              fullWidth
                              id="quantidadeUnidade"
                              label="Itens por caixa/unidade *"
                              margin="normal"
                              type="number"
                              onChange={handleChange('quantidadeUnidade')}
                              value={props.totvsInsumoUnidade.quantidadeUnidade}
                              disabled={!props.totvsInsumoUnidade.contagem}
                              validators={['required']}
                              errorMessages={['Campo obrigatório!']}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Grid container>
                        <Grid item lg={12} md={12} xl={12} xs={12} >
                          <Typography color="textPrimary">
                            <b>CONTAGEM</b>
                          </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                            <SelectValidator
                              className={classes.textField}
                              validators={['required']}
                              errorMessages={['Campo obrigatório!']}
                              variant="outlined"
                              id="idUnidadeContagem"
                              label="Unidade Contagem *"
                              value={props.totvsInsumoUnidade.idUnidadeContagem || ''}
                              onChange={handleChange('idUnidadeContagem')}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              displayEmpty
                              labelId="idUnidadeContagem"
                              variant="outlined"
                            >
                              <MenuItem disabled value="">
                                <em>Unidade Contagem *</em>
                              </MenuItem>
                              {!isEmpty(unidade) &&
                                undefined !== unidade &&
                                unidade.length &&
                                unidade.map((row, index) => (
                                  <MenuItem key={index} value={row.value}>
                                    {row.text}
                                  </MenuItem>
                                ))}
                            </SelectValidator>
                          </Grid>
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                            <TextValidator
                              className={classes.textField}
                              fullWidth
                              id="quantidadeUnidadeContagem"
                              label="Itens por caixa/unidade *"
                              margin="normal"
                              type="number"
                              onChange={handleChange('quantidadeUnidadeContagem')}
                              value={props.totvsInsumoUnidade.quantidadeUnidadeContagem}
                              validators={['required']}
                              errorMessages={['Campo obrigatório!']}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: '5px', marginBottom: '5px' }}>
                      <Divider />
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={11} >
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="pesoLiquido"
                        label="Peso líquido/Volumes (Conversão) *"
                        margin="normal"
                        type="number"
                        onChange={handleChange('pesoLiquido')}
                        value={props.totvsInsumoUnidade.pesoLiquido}
                        disabled={!props.totvsInsumoUnidade.contagem}
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={1} md={1} xl={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title="A cada quantas unidades/gramas/mls consumidas o sistema deve sugerir a compra de uma nova unidade do insumo"
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={11} >
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="pedidoMinimo"
                        label="Pedido Mínimo"
                        margin="normal"
                        type="number"
                        onChange={handleChange('pedidoMinimo')}
                        value={props.totvsInsumoUnidade.pedidoMinimo || 0}
                        disabled={!props.totvsInsumoUnidade.contagem}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={1} md={1} xl={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title="Quantidade mínima da unidade do insumo a ser informada ao realizar um pedido de compra"
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item lg={3} md={3} xl={3} xs={11} >
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="pedidoMultiplo"
                        label="Múltiplos de"
                        margin="normal"
                        onChange={handleChange('pedidoMultiplo')}
                        value={props.totvsInsumoUnidade.pedidoMultiplo || ''}
                        disabled={!props.totvsInsumoUnidade.contagem}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={1} md={1} xl={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title="Caso diferente de zero, a Quantidade deve ser múltipla do número informado ao realizar um pedido de compra. Informar o múltiplo (Ex: 3) ou as quantidades exatas aceitas separadas por ponto e vírgula (Ex: 1;2;5;10;15)."
                      >
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: '5px', marginBottom: '5px' }}>
                      <Divider />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Grid container>
                        <Grid item lg={12} md={12} xl={12} xs={12} >
                          <Typography color="textPrimary">
                            <b>INTEGRAÇÃO</b>
                          </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={3} md={3} xl={3} xs={11} >
                            <TextValidator
                              className={classes.textField}
                              fullWidth
                              id="pesoLiquidoIntegracao"
                              label="Peso líquido/Volumes (Conversão)"
                              margin="normal"
                              type="number"
                              onChange={handleChange('pesoLiquidoIntegracao')}
                              value={props.totvsInsumoUnidade.pesoLiquidoIntegracao || 0}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item lg={1} md={1} xl={1} xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip
                              title="A cada quantas unidades/gramas/mls consumidas o sistema deve sugerir a compra de uma nova unidade do insumo"
                            >
                              <HelpIcon />
                            </Tooltip>
                          </Grid>
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                            <SelectValidator
                              className={classes.textField}
                              variant="outlined"
                              id="idUnidadeIntegracao"
                              label="Unidade Integração"
                              value={props.totvsInsumoUnidade.idUnidadeIntegracao || ''}
                              onChange={handleChange('idUnidadeIntegracao')}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              displayEmpty
                              labelId="idUnidadeIntegracao"
                              variant="outlined"
                            >
                              <MenuItem disabled value="">
                                <em>Unidade Integração</em>
                              </MenuItem>
                              {!isEmpty(unidade) &&
                                undefined !== unidade &&
                                unidade.length &&
                                unidade.map((row, index) => (
                                  <MenuItem key={index} value={row.value}>
                                    {row.text}
                                  </MenuItem>
                                ))}
                            </SelectValidator>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ marginTop: '5px', marginBottom: '5px' }}>
                      <Divider />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} >
                      <SelectValidator
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                        variant="outlined"
                        id="idFornecedor"
                        label="Fornecedores vinculados *"
                        value={idFornecedor}
                        onChange={handleSelectChangeState('idFornecedor')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        displayEmpty
                        labelId="idFornecedor"
                      >
                        <MenuItem disabled value="" key={12}>
                          <em>Fornecedor *</em>
                        </MenuItem>
                        {!isEmpty(fornecedores) &&
                          undefined !== fornecedores &&
                          fornecedores.length &&
                          fornecedores.filter(excludeFilter).map((row, index) => (
                            <MenuItem key={'u' + index} value={row.value}>
                              {row.text}
                            </MenuItem>
                          ))}
                      </SelectValidator>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ alignItems: 'top', justifyContent: 'center', alignContent: 'center' }}>
                      <Button
                        style={{ width: '200px', marginLeft: '15px' }}
                        name="btnUsuarios"
                        id="btnUsuarios"
                        className="btn"
                        variant="contained"
                        color="primary"
                        component='a'
                        onClick={handleAddOnList}
                      >
                        Adicionar&nbsp; <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <div className="selectgroup selectgroup-pills" style={{ marginLeft: '5px', display: 'flex-row', alignItems: 'top' }}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Fornecedor</TableCell>
                                  <TableCell>Disponível</TableCell>
                                  <TableCell align="right">Ações</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                { props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores !== undefined &&
                                  props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.length ?
                                  props.totvsInsumoUnidade.totvsInsumosUnidadesFornecedores.map((n) => {
                                    return (
                                      <TableRow hover key={n.id}>
                                        <TableCell component="th" scope="row">
                                          {n.fornecedor ? n.fornecedor.nome : n.idFornecedor}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {n.disponivel ? 'SIM' :'NÃO'}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="right">
                                          <IconButton
                                            aria-label="Edit"
                                            onClick={() => handleOpenDialog(n)}
                                            size="small"
                                          >
                                            <Tooltip title={'Editar'}>
                                              <Tooltip>
                                                <EditIcon />
                                              </Tooltip>
                                            </Tooltip>
                                          </IconButton>
                                          <IconButton
                                            aria-label="Delete"
                                            onClick={() => handleRemoveOnList(n.idFornecedor)}
                                            size="small"
                                          >
                                            <Tooltip title="Excluir">
                                              <DeleteIcon />
                                            </Tooltip>
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                })
                                : null}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>

                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <Button
                      color="default"
                      onClick={() => history.push('/insumosUnidades')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.totvsInsumoUnidade.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.totvsInsumoUnidade.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.totvsInsumoUnidade.isLoading ? 'Atualizando insumo...' : 'Atualizar') : (props.totvsInsumoUnidade.isLoading ? 'Salvando insumo...' : 'Salvar')}
                    </Button>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openEdit}
        onClose={handleCloseDialog}
      >
        <DialogTitle>{"Editar Fornecedor"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Gostaria de alterar a disponibilidade desse fornecedor?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEditFornecedor} color="primary" autoFocus>
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    </Page >
  )
}

InsumoUnidadeEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedInsumoUnidadeEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(InsumoUnidadeEdit))
)
export { connectedInsumoUnidadeEditPage as InsumoUnidadeEdit }
