const initialState = {
  dashboardHome: {
    percentualParticipacao: 0,
    metasPercentualParticipacao: 0,
  },
  dashboardLoja: {
    totalLojas: 0,
    cuponsFidelizados: 0,
    rankingCuponsFidelizados: 0,
    valorCuponsFidelizados: 0,
    //valorTotalVendas: 0,
    percentualParticipacao: 0,
    metasPercentualParticipacao: 0,
    rankingpercentualParticipacao: 0,
    trocas: 0,
    pontosTrocas: 0,
    clientesFidelizados: 0,
    totalClientes: 0,
    rankingClientesFidelizados: 0,
    novosClientesFidelizados: 0,
    rankingNovosClientesFidelizados: 0,
    graficoFidelizacoesTrocas: { options: [], series: [] },
    graficoFidelizacoesTrocasHora: { options: [], series: [] },
    graficoFidelizacoesTipos: { options: [], series: [] },
    graficoClientesSexo: { options: [], series: [] },
    graficoClientesFaixaEtaria: { options: [], series: [] },
    graficoClientesRecorrenciaDias: { options: [], series: [] }
  },
  mensagens: [],
  topProdutosFidelizacoes: [],
  totalProdutosFidelizacoes: 0,
  topProdutosTrocas: [],
  totalProdutosTrocas: 0,
  topClientesFidelizacoes: [],
  totalClientesFidelizacoes: 0,
  clientesAniversariantes: [],
  totalClientesAniversariantes: 0,
  clientesSexo: [],
  totalClientesSexo: 0,
  clientesFaixaEtaria: [],
  totalClientesFaixaEtaria: 0,
  clientesRecorrenciaDias: [],
  totalClientesRecorrenciaDias: 0,
  isLoading: false,
  isLoadingClientes: false,
  isLoadingProdutos: false,
  isLoadingProdutosTrocas: false,
  isLoadingAniversariantes: false,
  isLoadingClientesAniversariantes: false,
  isLoadingClientesSexo: false,
  idLoadingClientesRecorrenciaDias: false
}

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_DASHBOARD_HOME_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_HOME_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dashboardHome: action.dashboardHome
      }
    case 'FETCHED_DASHBOARD_HOME_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_LOJA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        dashboardLoja: action.dashboardLoja,
        mensagens: action.mensagens
      }
    case 'FETCHED_DASHBOARD_LOJA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_PRODUTOS_FIDELIZACOES_REQUEST':
      return {
        ...state,
        isLoadingProdutos: true
      }
    case 'FETCHED_DASHBOARD_LOJA_PRODUTOS_FIDELIZACOES_SUCCESS':
      return {
        ...state,
        isLoadingProdutos: false,
        topProdutosFidelizacoes: action.topProdutosFidelizacoes,
        totalProdutosFidelizacoes: action.totalProdutosFidelizacoes
      }
    case 'FETCHED_DASHBOARD_LOJA_PRODUTOS_FIDELIZACOES_FAILURE':
      return {
        ...state,
        isLoadingProdutos: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_PRODUTOS_TROCAS_REQUEST':
      return {
        ...state,
        isLoadingProdutosTrocas: true
      }
    case 'FETCHED_DASHBOARD_LOJA_PRODUTOS_TROCAS_SUCCESS':
      return {
        ...state,
        isLoadingProdutosTrocas: false,
        topProdutosTrocas: action.topProdutosTrocas,
        totalProdutosTrocas: action.totalProdutosTrocas
      }
    case 'FETCHED_DASHBOARD_LOJA_PRODUTOS_TROCAS_FAILURE':
      return {
        ...state,
        isLoadingProdutosTrocas: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_FIDELIZACOES_REQUEST':
      return {
        ...state,
        isLoadingClientes: true
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_FIDELIZACOES_SUCCESS':
      return {
        ...state,
        isLoadingClientes: false,
        topClientesFidelizacoes: action.topClientesFidelizacoes,
        totalClientesFidelizacoes: action.totalClientesFidelizacoes
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_FIDELIZACOES_FAILURE':
      return {
        ...state,
        isLoadingClientes: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_ANIVERSARIANTES_REQUEST':
      return {
        ...state,
        isLoadingProdutosClientesAniversariantes: true
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_ANIVERSARIANTES_SUCCESS':
      return {
        ...state,
        isLoadingProdutosClientesAniversariantes: false,
        clientesAniversariantes: action.clientesAniversariantes,
        totalClientesAniversariantes: action.totalClientesAniversariantes
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_ANIVERSARIANTES_FAILURE':
      return {
        ...state,
        isLoadingProdutosClientesAniversariantes: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_SEXO_REQUEST':
      return {
        ...state,
        isLoadingClientesSexo: true
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_SEXO_SUCCESS':
      return {
        ...state,
        isLoadingClientesSexo: false,
        clientesSexo: action.clientesSexo,
        totalClientesSexo: action.totalClientesSexo
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_SEXO_FAILURE':
      return {
        ...state,
        isLoadingClientesSexo: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_FAIXAETARIA_REQUEST':
      return {
        ...state,
        isLoadingClientesSexo: true
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_FAIXAETARIA_SUCCESS':
      return {
        ...state,
        isLoadingClientesFaixaEtaria: false,
        clientesFaixaEtaria: action.clientesFaixaEtaria,
        totalClientesFaixaEtaria: action.totalClientesFaixaEtaria
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_FAIXAETARIA_FAILURE':
      return {
        ...state,
        isLoadingClientesFaixaEtaria: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_RECORRENCIA_DIAS_REQUEST':
      return {
        ...state,
        idLoadingClientesRecorrenciaDias: true
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_RECORRENCIA_DIAS_SUCCESS':
      return {
        ...state,
        idLoadingClientesRecorrenciaDias: false,
        clientesRecorrenciaDias: action.clientesRecorrenciaDias,
        totalClientesRecorrenciaDias: action.totalClientesRecorrenciaDias
      }
    case 'FETCHED_DASHBOARD_LOJA_CLIENTES_RECORRENCIA_DIAS_FAILURE':
      return {
        ...state,
        idLoadingClientesRecorrenciaDias: false,
        error: action.error
      }
    case 'DASHBOARD_CLEAR':
      return initialState
    default:
      return state
  }
}
