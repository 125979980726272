import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Chip,
  Grid,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from "@material-ui/pickers";
import {
  HistoricoPrecoMedioChartCard,
  RankingPrecoMedioChartCard
} from './components'
import { Page } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardCompraAction, totvsInsumoAction, totvsInsumoUnidadeAction } from '_actions'
import moment from 'moment'
import CardComparacaoPrecoMedio from './components/CardComparacaoPrecoMedio';
import HeatMapPrecoMedio from './components/HeatMapPrecoMedio';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
  },
  chips: {
    alignContent: 'center',
    justifyContent: 'center',
    "&&:hover": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
      transition: 0.3
    },
    "&&:focus": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.primary.contrastText,
      transition: 0.3
    }
  }
}))

const DashboardCompras = ({ location }) => {

  const queryString = location?.search;
  const params = new URLSearchParams(queryString);

  const classes = useStyles()
  const dispatch = useDispatch()
  const [dataInicio, setDataInicio] = useState(undefined)
  const [dataFim, setDataFim] = useState(undefined)

  const [valueInsumo, setValueInsumo] = useState(null);
  const [inputValueInsumo, setInputValueInsumo] = useState('');

  const [valueInsumoUnidade, setValueInsumoUnidade] = useState(null);
  const [inputValueInsumoUnidade, setInputValueInsumoUnidade] = useState('');

  const { totvsInsumo } = useSelector(
    (state) => state.totvsInsumo
  )

    const { totvsInsumoUnidade } = useSelector(
    (state) => state.totvsInsumoUnidade
  )

  const { rankingPrecoMedio } = useSelector(
    (state) => state.dashboardCompra
  )

  //const { estado } = useSelector(
  //  (state) => state.estado
  //)

  const optionsInsumos = ((totvsInsumo && totvsInsumo.length) ? totvsInsumo.map(u => ({ id: u.value, nome: u.text })) : []);

  const optionsInsumosUnidades = ((totvsInsumoUnidade && totvsInsumoUnidade.length) ? totvsInsumoUnidade.map(u => ({ id: u.value, nome: u.text })) : []);
  /*  const optionsEstado = (estado ? estado.map(u => ({ id: u.value, nome: u.text })) : []);*/

  const shouldUpdateValueInsumoUnidade = useRef(false);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const [noEstado, setNoEstado] = useState(!!idLoja);

  useEffect(() => {

    dispatch(totvsInsumoAction.getTotvsInsumoSelectComprasProdutos())
    //dispatch(estadoAction.getEstadoSelect())

    const id = params.get('id')
    if (id) {
      const initial = optionsInsumos.find(op => op.id == id)
      setValueInsumo(initial)
    }

    const initialDate = params.get('dataInicio')
    if (initialDate) {
      setDataInicio(moment(initialDate))
    } else {
      setDataInicio(moment().subtract(1, "months"))
    }

    const endDate = params.get('dataFim')
    if (endDate) {
      setDataFim(moment(endDate))
    } else {
      setDataFim(moment())
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (dataInicio && dataFim) {

      dispatch(dashboardCompraAction.clear())

      if (valueInsumo && valueInsumo.id != 0 && valueInsumoUnidade && valueInsumoUnidade.id != 0) {

        if (idLoja !== '') {

          let filterModel = {
            IdLoja: idLoja,
            IdTotvsInsumo: valueInsumo.id,
            IdTotvsInsumoUnidade: (valueInsumoUnidade != null ? valueInsumoUnidade.id : 0),
            NoEstado: noEstado || false,
            DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
            DataFim: moment(dataFim).format("YYYY-MM-DD")
          };

          dispatch(dashboardCompraAction.getDashboardLoja(filterModel))
        }
        else {

          let filterModel = {
            LojaId: 0,
            IdTotvsInsumo: valueInsumo.id,
            IdTotvsInsumoUnidade: (valueInsumoUnidade != null ? valueInsumoUnidade.id : 0),
            NoEstado: noEstado || false,
            DataInicio: moment(dataInicio).format("YYYY-MM-DD"),
            DataFim: moment(dataFim).format("YYYY-MM-DD")
          };

          dispatch(dashboardCompraAction.getDashboardLoja(filterModel))
        }

      }

    }

  }, [idLoja, dataInicio, dataFim, valueInsumo, valueInsumoUnidade, noEstado])

  useEffect(() => {

    if (valueInsumo && valueInsumo.id != 0) {

      dispatch(totvsInsumoUnidadeAction.getTotvsInsumoUnidadeSelectTotvsInsumo(valueInsumo.id))

    }

    setValueInsumoUnidade(null)

  }, [valueInsumo])  

  useEffect(() => {
    if (optionsInsumosUnidades.length > 0 && shouldUpdateValueInsumoUnidade.current) {
      setValueInsumoUnidade(optionsInsumosUnidades[0]);
      shouldUpdateValueInsumoUnidade.current = false;
    }
  }, [optionsInsumosUnidades]);

  useEffect(() => {
    dispatch(totvsInsumoUnidadeAction.clear())
    if (valueInsumo && valueInsumo.id != 0) {
      shouldUpdateValueInsumoUnidade.current = true;
    }
  }, [valueInsumo]);

  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue)
  }

  const handleChangeOntem = () => {
    const ontem = moment().subtract("1", "days");

    setDataInicio(ontem);
    setDataFim(ontem);
  }

  const handleChangeHoje = () => {
    const hoje = moment();

    setDataInicio(hoje);
    setDataFim(hoje);
  }

  const handleChange7Dias = () => {
    const hoje = moment();
    const semanaPassada = moment().subtract("7", "days");

    setDataInicio(semanaPassada);
    setDataFim(hoje);

  }

  const handleChange30Dias = () => {
    const hoje = moment();
    const mesPassado = moment().subtract("30", "days");

    setDataInicio(mesPassado);
    setDataFim(hoje);

  }

  const handleChange1Ano = () => {
    const hoje = moment();
    const anoPassado = moment().subtract("12", "months");

    setDataInicio(anoPassado);
    setDataFim(hoje);
  }

  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue)
  }

  const handleChangeNoEstado = (e) => {
    setNoEstado(e.target.checked)
  }

  const handleDataForHeatMap = () => {
    return rankingPrecoMedio?.map(item => ({
      lat: item.latitude,
      lng: item.longitude,
      weight: item.total
    }))
  }

  return (
    <Page className={classes.root} title="Painel de Dados - Compras">

      <Grid alignItems="flex-end" container spacing={3}>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Autocomplete
            value={valueInsumo}
            onChange={(event, newValue) => {
              setValueInsumo(newValue);
            }}
            inputValue={inputValueInsumo}
            onInputChange={(event, newInputValue) => {
              setInputValueInsumo(newInputValue);
            }}
            id="controllable-states-demo"
            getOptionSelected={(option, value) => option.id == value.id} 
            options={optionsInsumos}
            defaultValue={null}
            getOptionLabel={option => option.nome}
            renderInput={(params) => <TextField {...params} label="SELECIONE UM INSUMO..." fullWidth />}
            fullWidth
          />
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Autocomplete
            value={valueInsumoUnidade}
            onChange={(event, newValue) => {
              setValueInsumoUnidade(newValue);
            }}
            inputValue={inputValueInsumoUnidade}
            onInputChange={(event, newInputValue) => {
              setInputValueInsumoUnidade(newInputValue);
            }}
            id="controllable-states-demo"
            options={optionsInsumosUnidades}
            getOptionSelected={(option, value) => option.id == value.id} 
            defaultValue={null}
            getOptionLabel={option => option.nome}
            renderInput={(params) => <TextField {...params} label="SELECIONE UMA UNIDADE..." fullWidth />}
            fullWidth
          />
        </Grid>
        {/*<Grid item>
          <Autocomplete
            value={valueEstado}
            onChange={(event, newValue) => {
              setValueEstado(newValue);
            }}
            inputValue={inputValueEstado}
            onInputChange={(event, newInputValue) => {
              setInputValueEstado(newInputValue);
            }}
            id="controllable-states-demo"
            options={optionsEstado}
            defaultValue={null}
            getOptionLabel={option => option.nome}
            renderInput={(params) => <TextField {...params} label="SELECIONE UMA UF..." fullWidth />}
            style={{ width: "300px" }}
            fullWidth
          />
        </Grid>*/}
        <Grid item lg={2} md={2} xl={2} xs={6}>
          <DatePicker
            autoOk
            label="Início"
            clearable
            value={dataInicio}
            onChange={handleChangeDataInicio}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>
        <Grid item lg={2} md={2} xl={2} xs={6}>
          <DatePicker
            autoOk
            label="Fim"
            clearable
            value={dataFim}
            onChange={handleChangeDataFim}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="HOJE" onClick={handleChangeHoje} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="ONTEM" onClick={handleChangeOntem} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="7 DIAS" onClick={handleChange7Dias} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="30 DIAS" onClick={handleChange30Dias} />
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label="1 ANO" onClick={handleChange1Ano} />
        </Grid>
        <Grid item lg={2}>
          <FormControlLabel
            control={
              <Switch
                checked={noEstado}
                onChange={handleChangeNoEstado}
                color="primary"
                disabled={!idLoja}
              />
            }
            label="No meu estado"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>

        {(idLoja && valueInsumo && valueInsumo.id &&
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <CardComparacaoPrecoMedio type="loja" />
          </Grid>
        )}
        {(noEstado && valueInsumo && valueInsumo.id &&
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <CardComparacaoPrecoMedio type="estado" />
          </Grid>
        )}
        {(valueInsumo && valueInsumo.id &&
          <Grid item lg={4} sm={4} xl={4} xs={12}>
            <CardComparacaoPrecoMedio type="rede" />
          </Grid>
        )}

        <Grid item lg={12} md={12} xl={12} xs={12}>
          {(valueInsumo && valueInsumo.id) && <HistoricoPrecoMedioChartCard />}
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          {(dataInicio && dataFim && valueInsumo && valueInsumo.id) &&
            <RankingPrecoMedioChartCard idLoja={idLoja} dataInicio={dataInicio} dataFim={dataFim} idTotvsInsumo={(valueInsumo ? valueInsumo.id : null)} idTotvsInsumoUnidade={(valueInsumoUnidade ? valueInsumoUnidade.id : 0)} noEstado={noEstado || false} />
          }
        </Grid>
        {rankingPrecoMedio && rankingPrecoMedio.length > 0 &&
          < Grid item lg={6} md={6} xl={6} xs={12}>
            <HeatMapPrecoMedio data={handleDataForHeatMap()} />
          </Grid>
        }

      </Grid>


    </Page>
  )
}

export default DashboardCompras
