/// <reference path="../../_actions/loja.actions.js" />
import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { toast } from 'react-toastify'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from 'material-ui-confirm'
import {
  Button,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import {
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import { respostaChecklistOperacaoAction, checklistOperacaoAction, checklistOperacaoItemAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { EditorHeader, Page } from 'components'
import SaveIcon from '@material-ui/icons/Save'
import PrintIcon from '@material-ui/icons/Print'
import { isEmpty } from 'lodash'
import { useDebounce } from 'use-debounce';
import { DatePicker } from "@material-ui/pickers";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import palette from '../../theme/palette'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  descricao: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  variant60: {
    width: '60%',
  },
  variant50: {
    width: '50%',
  },
  tituloDelivery: {
    textAlign: 'center',
    alignItems: 'center',
    display: 'column',
    flex: 1,
    marginTop: 10
  },
  fontTypography: {
    fontSize: 13
  },
  fontTypographyUnidadeBig: {
    fontSize: 9
  },
  fontTypographyUnidade: {
    fontSize: 7
  },
  fontTypographyDias: {
    fontSize: 11,
    color: '#898989',
    marginTop: 5
  },
  fontNaoContabilizado: {
    fontSize: 20,
    color: '#898989'
  },
  boxItem: {
    borderRight: '1px solid #E0E0E0',
    paddingRight: '6px'
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 800
  },
  stickyButtons: {
    bottom: 0,
    position: '-webkit-sticky',
    position: 'sticky',
    zIndex: 800,
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E2E3',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5
  },
  buttonGrid: {
    paddingBottom: 8,
    paddingRight: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingRight: 0
    },
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row',
      alignItems: 'right',
      textAlign: 'right',
      justifyContent: 'right',
    }
  }
})

const RespostaChecklistOperacaoEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { classes, checklistOperacao, respostaChecklistOperacao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const theme = useTheme()

  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 200);
  const [filteredItens, setFilteredItens] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [, forceUpdate] = React.useState(0);

  const checklistOperacaoList = useSelector((state) => state.checklistOperacao.checklistOperacao)

  const [termOperacao, setTermOperacao] = useState('');
  const [debounceTermOperacao] = useDebounce(termOperacao, 1000);

  const [load, setLoad] = useState(false);

  const [dias, setDias] = useState([]);

  const [dataInicio, setDataInicio] = React.useState(undefined)
  const [dataFim, setDataFim] = React.useState(undefined)

  const isMobileResolution = useMediaQuery(theme.breakpoints.down('xs'));
  const [isMobile, setIsMobile] = useState(false);

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const checklistOperacaoItem = useSelector(
    (state) => state.checklistOperacaoItem.checklistOperacaoItem.checklistsOperacaoItens
  )

  const { dataUltimaVendaImportada } = useSelector(
    (state) => state.checklistOperacaoItem.checklistOperacaoItem
  )

  const tipo = useSelector(
    (state) => state.checklistOperacaoItem.checklistOperacaoItem.tipo
  )

  const isLoadingChecklistOperacaoItem = useSelector(
    (state) => state.checklistOperacaoItem.isLoading
  )

  useEffect(() => {

    dispatch(checklistOperacaoItemAction.clear())
    dispatch(checklistOperacaoAction.getChecklistOperacaoSelect());
    dispatch(checklistOperacaoAction.getChecklistOperacaoById(params.id))

    //if (params && params.id) {

    dispatch(respostaChecklistOperacaoAction.clear())
    //dispatch(respostaChecklistOperacaoAction.getRespostaChecklistOperacaoById(params.id))

    let event = {
      type: 'text',
      target: {
        value: params.id
      }
    }
    dispatch(respostaChecklistOperacaoAction.onChangeProps('idChecklistOperacao', event))

    //} else {
    //  dispatch(respostaChecklistOperacaoAction.clear())
    //  dispatch(respostaChecklistOperacaoAction.clearRespostasChecklistsOperacaoProducao())
    //  dispatch(respostaChecklistOperacaoAction.clearRespostasChecklistsOperacaoTemperatura())
    //  dispatch(respostaChecklistOperacaoAction.clearRespostasChecklistsOperacaoCheck())
    //}

    if (idLoja === '' || idLoja === undefined) {
      toast.error('Selecione uma Loja');
      history.push('/checklistsOperacoesCards/categorias');
    }

    setLoad(true);


    setDataInicio(moment().startOf('week').add('days', 1))
    setDataFim(moment().endOf('week').add('days', 1))

  }, []);

  useEffect(() => {
    setIsMobile(isMobileResolution);
  }, [isMobileResolution]);

  useEffect(() => {

    if (dataInicio != undefined && dataFim != undefined) {

      var startOfWeek = dataInicio;
      var endOfWeek = dataFim;

      //startOfWeek.add('days', -1);
      //endOfWeek.add('days', -1);

      var day = startOfWeek;
      var days = [];

      while (day <= endOfWeek) {
        days.push(moment(day).format('YYYY-MM-DD'));
        day = moment(day).add('days', 1)
      }

      setDias(days);

    }

  }, [dataInicio, dataFim]);

  useEffect(() => {

    if (load && props.respostaChecklistOperacao.idChecklistOperacao !== 0 && props.respostaChecklistOperacao.idChecklistOperacao !== '' && props.respostaChecklistOperacao.idChecklistOperacao !== undefined) {

      setFilteredItens([])

      let filterModel = {
        IdChecklistOperacao: props.respostaChecklistOperacao.idChecklistOperacao,
        IdLoja: idLoja,
        DataInicial: moment(dataInicio).format('YYYY-MM-DD'),
        DataFinal: moment(dataFim).format('YYYY-MM-DD')
      };
      dispatch(checklistOperacaoItemAction.getChecklistOperacaoItemSemana(filterModel, true))

      let filterModel1 = {
        IdChecklistOperacao: props.respostaChecklistOperacao.idChecklistOperacao,
        IdLoja: idLoja,
        DataInicial: moment(dataInicio).format('YYYY-MM-DD'),
        DataFinal: moment(dataFim).format('YYYY-MM-DD')
      };
      dispatch(respostaChecklistOperacaoAction.getRespostaChecklistOperacaoBySemana(filterModel1, true))

    }

  }, [load, props.respostaChecklistOperacao.idChecklistOperacao, dataInicio, dataFim]);

  useEffect(() => {
    if (checklistOperacaoItem) {
      setFilteredItens(checklistOperacaoItem.filter(filterTerm))
    }
  }, [checklistOperacaoItem, debounceTerm]);

  function filterTerm(obj) {
    if (obj) {
      if (debounceTerm == null || debounceTerm == '' || (obj.descricao != null && obj.descricao != '' && (obj.descricao.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()).includes(debounceTerm.normalize("NFD").replace(/\p{Diacritic}/gu, "").toUpperCase()))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const handleChange = (prop) => (event) => {
    dispatch(respostaChecklistOperacaoAction.onChangeProps(prop, event))
  }

  const handleChangeTerm = prop => event => {
    setTerm(event.target.value);
  };

  //const handleChangeContagem = (event, prop, n) => {

  //  var respostasChecklistsOperacaoProducao = props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao;

  //  if (respostasChecklistsOperacaoProducao && respostasChecklistsOperacaoProducao.length > 0) {

  //    var index = respostasChecklistsOperacaoProducao.findIndex(x => x.idChecklistOperacaoItem == n.id);
  //    if (index >= 0) {

  //      const novasRespostasChecklistsOperacaoProducao = props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao.map(x =>
  //        x.idChecklistOperacaoItem == n.id ? { ...x, contagem: parseFloat(event.target.value) } : x
  //      );
  //      dispatch(respostaChecklistOperacaoAction.onChangeProps('respostasChecklistsOperacaoProducao', novasRespostasChecklistsOperacaoProducao))

  //    } else {

  //      let payload = {
  //        id: 0,
  //        idRespostaChecklistOperacao: (params.id ? params.id : 0),
  //        idChecklistOperacaoItem: n.id,
  //        contagem: parseFloat(event.target.value)
  //      }
  //      respostasChecklistsOperacaoProducao.push(payload);
  //      dispatch(respostaChecklistOperacaoAction.onChangeProps('respostasChecklistsOperacaoProducao', respostasChecklistsOperacaoProducao))
  //    }
  //  } else {

  //    let payload = {
  //      id: 0,
  //      idRespostaChecklistOperacao: (params.id ? params.id : 0),
  //      idChecklistOperacaoItem: n.id,
  //      contagem: parseFloat(event.target.value)
  //    }

  //    respostasChecklistsOperacaoProducao.push(payload);
  //    dispatch(respostaChecklistOperacaoAction.onChangeProps('respostasChecklistsOperacaoProducao', respostasChecklistsOperacaoProducao))
  //  }

  //};

  const handleChangeContagem = (event, prop, n, data) => {

    var index = 0;
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      index = props.respostaChecklistOperacao.respostaChecklistOperacao.findIndex(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
    }

    if (index >= 0 && props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.length > 0) {

      var index1 = props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.findIndex(x => x.idChecklistOperacaoItem == n.id);
      if (index1 >= 0) {

        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao[index1].contagem = parseFloat(event.target.value);
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      } else {

        let payload = {
          id: 0,
          idRespostaChecklistOperacao: (params.id ? params.id : 0),
          idChecklistOperacaoItem: n.id,
          contagem: parseFloat(event.target.value)
        }
        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.push(payload)
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      }

    }
    else {

      let payload = {
        id: 0,
        idRespostaChecklistOperacao: (params.id ? params.id : 0),
        idChecklistOperacaoItem: n.id,
        contagem: parseFloat(event.target.value)
      }
      props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.push(payload)
      dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

    }

  }

  const handleChangeTemperatura1 = (event, prop, n, data) => {

    var index = 0;
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      index = props.respostaChecklistOperacao.respostaChecklistOperacao.findIndex(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
    }

    if (index >= 0 && props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.length > 0) {

      var index1 = props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.findIndex(x => x.idChecklistOperacaoItem == n.id);
      if (index1 >= 0) {

        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura[index1].temperatura1 = parseFloat(event.target.value);
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      } else {

        let payload = {
          id: 0,
          idRespostaChecklistOperacao: (params.id ? params.id : 0),
          idChecklistOperacaoItem: n.id,
          temperatura1: parseFloat(event.target.value)
        }
        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.push(payload)
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      }

    }
    else {

      let payload = {
        id: 0,
        idRespostaChecklistOperacao: (params.id ? params.id : 0),
        idChecklistOperacaoItem: n.id,
        temperatura1: parseFloat(event.target.value)
      }
      props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.push(payload)
      dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

    }

  }

  const handleChangeTemperatura2 = (event, prop, n, data) => {

    var index = 0;
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      index = props.respostaChecklistOperacao.respostaChecklistOperacao.findIndex(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
    }

    if (index >= 0 && props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.length > 0) {

      var index1 = props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.findIndex(x => x.idChecklistOperacaoItem == n.id);
      if (index1 >= 0) {

        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura[index1].temperatura2 = parseFloat(event.target.value);
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      } else {

        let payload = {
          id: 0,
          idRespostaChecklistOperacao: (params.id ? params.id : 0),
          idChecklistOperacaoItem: n.id,
          temperatura2: parseFloat(event.target.value)
        }
        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.push(payload)
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      }

    }
    else {

      let payload = {
        id: 0,
        idRespostaChecklistOperacao: (params.id ? params.id : 0),
        idChecklistOperacaoItem: n.id,
        temperatura2: parseFloat(event.target.value)
      }
      props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoTemperatura.push(payload)
      dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

    }

  }

  //const handleChangeProducao = (event, prop, n) => {

  //  var respostasChecklistsOperacaoProducao = props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao;

  //  if (respostasChecklistsOperacaoProducao && respostasChecklistsOperacaoProducao.length > 0) {

  //    var index = respostasChecklistsOperacaoProducao.findIndex(x => x.idChecklistOperacaoItem == n.id);
  //    if (index >= 0) {

  //      const novosPedidosComprasProdutos = props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao.map(x =>
  //        x.idChecklistOperacaoItem == n.id ? { ...x, producao: parseFloat(event.target.value) } : x
  //      );
  //      dispatch(respostaChecklistOperacaoAction.onChangeProps('respostasChecklistsOperacaoProducao', novosPedidosComprasProdutos))

  //    } else {

  //      let payload = {
  //        id: 0,
  //        idRespostaChecklistOperacao: (params.id ? params.id : 0),
  //        idChecklistOperacaoItem: n.id,
  //        producao: parseFloat(event.target.value)
  //      }
  //      respostasChecklistsOperacaoProducao.push(payload);
  //      dispatch(respostaChecklistOperacaoAction.onChangeProps('respostasChecklistsOperacaoProducao', respostasChecklistsOperacaoProducao))
  //    }
  //  } else {

  //    let payload = {
  //      id: 0,
  //      idRespostaChecklistOperacao: (params.id ? params.id : 0),
  //      idChecklistOperacaoItem: n.id,
  //      producao: parseFloat(event.target.value)
  //    }

  //    respostasChecklistsOperacaoProducao.push(payload);
  //    dispatch(respostaChecklistOperacaoAction.onChangeProps('respostasChecklistsOperacaoProducao', respostasChecklistsOperacaoProducao))
  //  }

  //};

  const handleChangeProducao = (event, prop, n, data) => {

    var index = 0;
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      index = props.respostaChecklistOperacao.respostaChecklistOperacao.findIndex(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
    }

    if (index >= 0 && props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.length > 0) {

      var index1 = props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.findIndex(x => x.idChecklistOperacaoItem == n.id);
      if (index1 >= 0) {

        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao[index1].producao = parseFloat(event.target.value);
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      } else {

        let payload = {
          id: 0,
          idRespostaChecklistOperacao: (params.id ? params.id : 0),
          idChecklistOperacaoItem: n.id,
          producao: parseFloat(event.target.value)
        }
        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.push(payload)
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      }

    }
    else {

      let payload = {
        id: 0,
        idRespostaChecklistOperacao: (params.id ? params.id : 0),
        idChecklistOperacaoItem: n.id,
        producao: parseFloat(event.target.value)
      }
      props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoProducao.push(payload)
      dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

    }

  }

  const handleChangeCheck = (event, prop, n, data) => {

    var index = 0;
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      index = props.respostaChecklistOperacao.respostaChecklistOperacao.findIndex(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
    }

    if (index >= 0 && props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoCheck.length > 0) {

      var index1 = props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoCheck.findIndex(x => x.idChecklistOperacaoItem == n.id);
      if (index1 >= 0) {

        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoCheck[index1].check = event.target.checked;
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      } else {

        let payload = {
          id: 0,
          idRespostaChecklistOperacao: (params.id ? params.id : 0),
          idChecklistOperacaoItem: n.id,
          check: event.target.checked
        }
        props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoCheck.push(payload)
        dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

      }

    }
    else {

      let payload = {
        id: 0,
        idRespostaChecklistOperacao: (params.id ? params.id : 0),
        idChecklistOperacaoItem: n.id,
        check: event.target.checked
      }
      props.respostaChecklistOperacao.respostaChecklistOperacao[index].respostasChecklistsOperacaoCheck.push(payload)
      dispatch(respostaChecklistOperacaoAction.changeChecklistOperacoesList(props.respostaChecklistOperacao.respostaChecklistOperacao, null))

    }

  };

  //function getContagem(codigo) {
  //  if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao && props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao.length > 0) {
  //    const item = props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao.find(p => p.idChecklistOperacaoItem == codigo);
  //    if (item && item.contagem) {
  //      return item.contagem;
  //    } else {
  //      return null;
  //    }
  //  } else {
  //    return null;
  //  }
  //}

  const handlePrint = () => {

    if (idLoja === '' || idLoja === undefined) {
      toast.error('Selecione uma Loja');
    }
    else {

      //if (n.tipo == 'D') {
      //  window.open('/relatorioCheckListOperacao/' + n.id, '_blank');
      //} else {
      //  window.open('/relatorioCheckList/' + n.id, '_blank');
      //}

      window.open('/relatorioCheckListOperacao/' + params.id + '/' + moment(dataInicio).format("YYYY-MM-DD") + '/' + moment(dataFim).format("YYYY-MM-DD"), '_blank');

    }

  }

  function getContagem(codigo, data) {
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      const itemRespostaChecklistOperacao = props.respostaChecklistOperacao.respostaChecklistOperacao.find(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
      if (itemRespostaChecklistOperacao) {

        if (itemRespostaChecklistOperacao && itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.length > 0) {
          const item = itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.find(p => p.idChecklistOperacaoItem == codigo);
          if (item && item.contagem) {
            return item.contagem;
          } else {
            return null;
          }

        } else {
          return null;
        }

      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  //function getProducao(codigo) {
  //  if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao && props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao.length > 0) {
  //    const item = props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao.find(p => p.idChecklistOperacaoItem == codigo);
  //    if (item && item.producao) {
  //      return item.producao;
  //    } else {
  //      return null;
  //    }
  //  } else {
  //    return null;
  //  }
  //}

  function getProducao(codigo, data) {
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      const itemRespostaChecklistOperacao = props.respostaChecklistOperacao.respostaChecklistOperacao.find(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
      if (itemRespostaChecklistOperacao) {

        if (itemRespostaChecklistOperacao && itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.length > 0) {
          const item = itemRespostaChecklistOperacao.respostasChecklistsOperacaoProducao.find(p => p.idChecklistOperacaoItem == codigo);
          if (item && item.producao) {
            return item.producao;
          } else {
            return null;
          }

        } else {
          return null;
        }

      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  //function getTemperatura1(codigo) {
  //  if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura && props.respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.length > 0) {
  //    const item = props.respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.find(p => p.idChecklistOperacaoItem == codigo);
  //    if (item && item.temperatura1) {
  //      return item.temperatura1;
  //    } else {
  //      return null;
  //    }
  //  } else {
  //    return null;
  //  }
  //}

  function getTemperatura1(codigo, data) {
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      const itemRespostaChecklistOperacao = props.respostaChecklistOperacao.respostaChecklistOperacao.find(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
      if (itemRespostaChecklistOperacao) {

        if (itemRespostaChecklistOperacao && itemRespostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.length > 0) {
          const item = itemRespostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.find(p => p.idChecklistOperacaoItem == codigo);
          if (item && item.temperatura1) {
            return item.temperatura1;
          } else {
            return null;
          }

        } else {
          return null;
        }

      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  //function getTemperatura2(codigo) {
  //  if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura && props.respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.length > 0) {
  //    const item = props.respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.find(p => p.idChecklistOperacaoItem == codigo);
  //    if (item && item.temperatura2) {
  //      return item.temperatura2;
  //    } else {
  //      return null;
  //    }
  //  } else {
  //    return null;
  //  }
  //}

  function getTemperatura2(codigo, data) {
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      const itemRespostaChecklistOperacao = props.respostaChecklistOperacao.respostaChecklistOperacao.find(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
      if (itemRespostaChecklistOperacao) {

        if (itemRespostaChecklistOperacao && itemRespostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.length > 0) {
          const item = itemRespostaChecklistOperacao.respostasChecklistsOperacaoTemperatura.find(p => p.idChecklistOperacaoItem == codigo);
          if (item && item.temperatura2) {
            return item.temperatura2;
          } else {
            return null;
          }

        } else {
          return null;
        }

      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function isTemperaturaDisabled(data) {

    if (moment().diff(data, 'days') > 2) {
      return true;
    }
    else {
      return false;
    }

  }

  function getCheck(codigo, data) {
    if (props && props.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao && props.respostaChecklistOperacao.respostaChecklistOperacao.length > 0) {
      const itemRespostaChecklistOperacao = props.respostaChecklistOperacao.respostaChecklistOperacao.find(p => moment(p.dataHora, "YYYY-MM-DD").isSame(moment(data, "YYYY-MM-DD")));
      if (itemRespostaChecklistOperacao) {

        if (itemRespostaChecklistOperacao && itemRespostaChecklistOperacao.respostasChecklistsOperacaoCheck.length > 0) {
          const item = itemRespostaChecklistOperacao.respostasChecklistsOperacaoCheck.find(p => p.idChecklistOperacaoItem == codigo);
          if (item && item.check) {
            return item.check;
          } else {
            return null;
          }

        } else {
          return null;
        }

      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const handleSubmit = (event) => {

    //let payload = {
    //  id: params.id,
    //  idChecklistOperacao: props.respostaChecklistOperacao.idChecklistOperacao,
    //  dataHora: moment().format('YYYY-MM-DD HH:mm'),
    //  idUsuario: 0,
    //  idLoja: idLoja,
    //  respostasChecklistsOperacaoProducao: props.respostaChecklistOperacao.respostasChecklistsOperacaoProducao,
    //  respostasChecklistsOperacaoTemperatura: props.respostaChecklistOperacao.respostasChecklistsOperacaoTemperatura,
    //  respostasChecklistsOperacaoCheck: props.respostaChecklistOperacao.respostasChecklistsOperacaoCheck
    //}

    //if (params.id) {
    //  dispatch(respostaChecklistOperacaoAction.editRespostaChecklistOperacaoInfo(params.id, payload, true))
    //} else {
    //  dispatch(respostaChecklistOperacaoAction.createRespostaChecklistOperacao(payload))
    //}

    dispatch(respostaChecklistOperacaoAction.createRespostaChecklistOperacaoSemana(props.respostaChecklistOperacao.respostaChecklistOperacao))

  };

  const handleChangeDataInicio = (newValue) => {
    setDataInicio(newValue)
  }

  const handleChangeDataFim = (newValue) => {
    setDataFim(newValue)
  }

  const handleClickBack = () => {
    setDataInicio(moment(dataInicio).add('days', -7))
    setDataFim(moment(dataFim).add('days', -7))
  }

  const handleClickNext = () => {
    setDataInicio(moment(dataInicio).add('days', 7))
    setDataFim(moment(dataFim).add('days', 7))
  }

  const getColor = () => {

    var diff = moment().diff(moment(dataUltimaVendaImportada, 'DD/MM/YYYY'), 'days')
    if (diff > 1) {
      return palette.error.main
    }
    else {
      return palette.text.primary
    }

  }

  const getEstoque = (estoque, unidadeProducao) => {
    let value = ''

    if (unidadeProducao != null) {
      if (unidadeProducao.arredondado) {
        value = Math.round(estoque).toLocaleString('pt-br')
      } else {
        value = estoque.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      }

      return value + ' ' + unidadeProducao.abreviacao
    }

    return value
  }

  const getCalculoDias = (coeficiente, diasVencimento) => {
    if (coeficiente != null && diasVencimento != null) {
      let result = Number((coeficiente * (diasVencimento + 1)).toFixed(1))

      if (result % 1 === 0) {
        result = Math.floor(result)
      }

      return result.toLocaleString('pt-br') + " dia(s)"
    }

    return ''
  }

  function InsertText() {
    return (
      <Typography variant="h4">
        <b>{'Nova Resposta de Checklist de Operação'}</b>
      </Typography>
    )
  }

  function SubHeaderCard() {
    return (
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography style={{ marginRight: '10px' }}>Cadastro de Respostas de Checklists de Operação</Typography>
        {dataUltimaVendaImportada != null && dataUltimaVendaImportada != '' &&
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography>Última atualização:</Typography>
            <Typography style={{ color: getColor(), marginLeft: '5px' }}>{dataUltimaVendaImportada}</Typography>
            <Typography style={{ marginRight: '10px' }}>.</Typography>
          </Box>
        }
      </Box>
    )
  }

  function UnidadeTypography(n) {
    var abreviacao = n.unidadeProducao != null ? n.unidadeProducao.abreviacao : ''

    return (
      n.totvsInsumo != null ?
        <Typography position="left" className={abreviacao.length <= 3 ? classes.fontTypographyUnidadeBig : classes.fontTypographyUnidade}>
          {abreviacao}
        </Typography>
        : ''
    )
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Resposta de Checklist de Operação - Editar Resposta de Checklist de Operação'
          : 'Gerência de Resposta de Checklist de Operação - Nova Resposta de Checklist de Operação'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        {
          checklistOperacao !== null &&
            checklistOperacao.instrucao &&
            !isEmpty(checklistOperacao.instrucao) ?
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Alert variant="outlined" severity="info">
                <AlertTitle><strong>Instruções do Checklist de Operação</strong></AlertTitle>
                {checklistOperacao.instrucao}
              </Alert>
            </Grid>
            : null
        }

        <Grid item lg={12} md={12} xl={12} xs={12}>
          <ValidatorForm
            className={classes.form}
            ref={formRef}
            id="formRespostas"
            onSubmit={(event) => handleSubmit(event)}
          >
            <Grid item md={12} xs={12}>
              <Card>
                <CardHeader
                  subheader={<SubHeaderCard />}
                  title={(
                    params.id ?
                      <EditorHeader title='Editar Resposta de Checklist de Operação' tabela='RespostaChecklistOperacao' chave={params.id} props={props} />
                    :
                      <InsertText />
                  )}
                />
                <Divider />
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      {params.id ?
                        <SelectValidator
                          variant="outlined"
                          id="idChecklistOperacao"
                          label="Checklist de Operação *"
                          value={props.respostaChecklistOperacao.idChecklistOperacao}
                          onChange={handleChange('idChecklistOperacao')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          disabled
                          displayEmpty
                          labelId="idChecklistOperacao"
                        >
                          <MenuItem disabled value="" key={12}>
                            <em>Checklist de Operação *</em>
                          </MenuItem>
                          {!isEmpty(checklistOperacaoList) &&
                            undefined !== checklistOperacaoList &&
                            checklistOperacaoList.length &&
                            checklistOperacaoList.map((row, index) => (
                              <MenuItem key={'u' + index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                        :
                        <SelectValidator
                          variant="outlined"
                          id="idChecklistOperacao"
                          label="Checklist de Operação *"
                          value={props.respostaChecklistOperacao.idChecklistOperacao}
                          onChange={handleChange('idChecklistOperacao')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="idChecklistOperacao"
                        >
                          <MenuItem disabled value="" key={12}>
                            <em>Checklist de Operação *</em>
                          </MenuItem>
                          {!isEmpty(checklistOperacaoList) &&
                            undefined !== checklistOperacaoList &&
                            checklistOperacaoList.length &&
                            checklistOperacaoList.map((row, index) => (
                              <MenuItem key={'u' + index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      }
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12} align="right">
                      <Grid
                        style={{ justifyContent: 'right', alignContent: 'end' }}
                        container
                        spacing={3}
                      >
                        <Grid item>
                          <DatePicker
                            autoOk
                            label="Início"
                            clearable
                            value={dataInicio}
                            onChange={handleChangeDataInicio}
                            format="dd/MM/yyyy"
                            disabled
                          />
                        </Grid>
                        <Grid item>
                          <DatePicker
                            autoOk
                            label="Fim"
                            clearable
                            value={dataFim}
                            onChange={handleChangeDataFim}
                            format="dd/MM/yyyy"
                            disabled
                          />
                        </Grid>
                        <Grid item>
                          <IconButton color="inherit" onClick={handleClickBack}>
                            <KeyboardArrowLeftIcon />
                          </IconButton>
                          <IconButton color="inherit" onClick={handleClickNext}>
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {(props.respostaChecklistOperacao.idChecklistOperacao && props.respostaChecklistOperacao.idChecklistOperacao != '' ?
                    (tipo == 'P' ?
                      <>
                        <Grid container spacing={2}>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Grid item xl={4} lg={4} md={4} xs={12} style={{ marginTop: 20 }}>
                              <Input
                                id="term"
                                label="term"
                                placeholder="Filtro"
                                value={term}
                                onChange={handleChangeTerm('term')}
                                fullWidth
                                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                              />
                            </Grid>
                            <br />
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell colSpan={2} rowSpan={2} className={classes.stickyCell} style={{ backgroundColor: '#FAFAFA' }}>
                                      Item
                                    </TableCell>
                                    <TableCell rowSpan={2} align="center">Validade (dias)</TableCell>
                                    <TableCell rowSpan={2} align="center">
                                      <Tooltip
                                        title={
                                          <span>
                                            <p>Fórmula: ((Validade + 1) x Coeficiente mínimo x Média 21 dias) / Peso Líquido</p>
                                          </span>
                                        }
                                        arrow
                                      >
                                        <p>Estoque mínimo</p>
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell rowSpan={2} align="center">
                                      <Tooltip
                                        title={
                                          <span>
                                            <p>Fórmula: ((Validade + 1) x Coeficiente máximo x Média 21 dias) / Peso Líquido</p>
                                          </span>
                                        }
                                        arrow
                                      >
                                        <p>Estoque máximo</p>
                                      </Tooltip>
                                    </TableCell>
                                    {
                                      (
                                        undefined !== dias && dias.length ?
                                          dias.map((dia) =>
                                            <TableCell align="center" colSpan={3}>{moment(dia).format('DD/MM/YYYY')}</TableCell>
                                          )
                                          :
                                          null
                                      )
                                    }
                                  </TableRow>
                                  <TableRow>
                                    {
                                      (
                                        undefined !== dias && dias.length ?
                                          dias.map((dia, i) =>
                                            <>
                                              <TableCell colSpan={3} component="th" scope="row" align="center">

                                                <Grid container spacing={1}>
                                                  <Grid item lg={12} md={12} xl={12} xs={12}>
                                                    Contagem Prod.
                                                  </Grid>
                                                  <Grid item lg={12} md={12} xl={12} xs={12}>
                                                    <Tooltip
                                                      title={
                                                        <span>
                                                          <p>Venda da semana anterior</p>
                                                          <p>{moment(dia).add(-7, 'days').format('DD/MM/YYYY')}</p>
                                                        </span>
                                                      }
                                                      arrow
                                                    >
                                                      <p>
                                                        {
                                                          (
                                                            i == 0 ? 'Seg passada' :
                                                              i == 1 ? 'Ter passada' :
                                                                i == 2 ? 'Qua passada' :
                                                                  i == 3 ? 'Qui passada' :
                                                                    i == 4 ? 'Sex passada' :
                                                                      i == 5 ? 'Sáb passado' :
                                                                        i == 6 ? 'Dom passado' :
                                                                          ''
                                                          )
                                                        }
                                                      </p>
                                                    </Tooltip>
                                                  </Grid>
                                                  {/*<Grid item lg={6} md={6} xl={6} xs={6}>*/}
                                                  {/*  <Tooltip*/}
                                                  {/*    title={*/}
                                                  {/*      <span>*/}
                                                  {/*        <p>Venda do dia anterior</p>*/}
                                                  {/*        <p>{moment(dia).add(-1, 'days').format('DD/MM/YYYY')}</p>*/}
                                                  {/*      </span>*/}
                                                  {/*    }*/}
                                                  {/*    arrow*/}
                                                  {/*  >*/}
                                                  {/*    <p>Dia Ant</p>*/}
                                                  {/*  </Tooltip>*/}
                                                  {/*</Grid>*/}
                                                </Grid>

                                              </TableCell>
                                            </>
                                          )
                                          :
                                          null
                                      )
                                    }
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {

                                    idLoja !== '' && idLoja !== undefined && undefined !== filteredItens && filteredItens.length && undefined !== dias && dias.length
                                      ?
                                      filteredItens.map((n) => {
                                        return (
                                          <TableRow hover key={(n ? n.id : 0)}>
                                            <TableCell component="th" scope="row" fullWidth colSpan={2} className={classes.stickyCell} style={{ backgroundColor: '#FFF' }}>
                                              <Typography position="left" className={classes.fontTypography}>{n.descricao}</Typography>
                                            </TableCell>

                                            {
                                              n.totvsInsumo?.contabilizado ?
                                                <>
                                                  <TableCell component="th" scope="row" fullWidth >
                                                    <Typography align="center" className={classes.fontTypography}>{(n.diasVencimento != null && n.diasVencimento != null ? n.diasVencimento.toLocaleString('pt-br', { minimumFractionDigits: 0 }) : '')}</Typography>
                                                  </TableCell>
                                                  <TableCell component="th" scope="row" fullWidth >
                                                    {
                                                      (n.estoqueMinimo != null ?
                                                        <>
                                                          <Typography align="center" className={classes.fontTypography}>
                                                            {getEstoque(n.estoqueMinimo, n.unidadeProducao)}
                                                          </Typography>
                                                          <Typography align="center" className={classes.fontTypographyDias}>
                                                            {getCalculoDias(n.coeficienteEstoqueMinimo, n.diasVencimento)}
                                                          </Typography>
                                                        </>
                                                        : '')
                                                    }
                                                  </TableCell>
                                                  <TableCell component="th" scope="row" fullWidth >
                                                    {
                                                      (n.estoqueMaximo != null ?
                                                        <>
                                                          <Typography align="center" className={classes.fontTypography}>
                                                            {getEstoque(n.estoqueMaximo, n.unidadeProducao)}
                                                          </Typography>
                                                          <Typography align="center" className={classes.fontTypographyDias}>
                                                            {getCalculoDias(n.coeficienteEstoqueMaximo, n.diasVencimento)}
                                                          </Typography>
                                                        </>
                                                        : '')
                                                    }
                                                  </TableCell>
                                                </>
                                                :
                                                <TableCell component="th" scope="row" fullWidth colSpan={3}>
                                                  <Typography align="center" className={classes.fontNaoContabilizado}>
                                                    Não Contabilizado
                                                  </Typography>
                                                </TableCell>
                                            }

                                            {

                                              dias.map((dia, i) =>

                                                <>
                                                  <TableCell colSpan={3} component="th" scope="row" align="center">

                                                    <Grid container spacing={1} className={i != dias.length - 1 ? classes.boxItem : ''}>
                                                      <Grid item lg={6} md={6} xl={6} xs={6}>
                                                        <Input
                                                          id="contagem"
                                                          label="contagem"
                                                          autoComplete="off"
                                                          className="form-control"
                                                          type="number"
                                                          value={getContagem(n.id, dia)}
                                                          endAdornment={UnidadeTypography(n)}
                                                          onChange={(event) => handleChangeContagem(event, 'contagem', n, dia)}
                                                          inputProps={{
                                                            min: 0,
                                                            step: "0.1"
                                                          }}
                                                        />
                                                      </Grid>
                                                      <Grid item lg={6} md={6} xl={6} xs={6}>
                                                        <Input
                                                          id="producao"
                                                          label="producao"
                                                          autoComplete="off"
                                                          className="form-control"
                                                          type="number"
                                                          value={getProducao(n.id, dia)}
                                                          endAdornment={UnidadeTypography(n)}
                                                          onChange={(event) => handleChangeProducao(event, 'producao', n, dia)}
                                                          inputProps={{
                                                            min: 0,
                                                            step: "0.1"
                                                          }}
                                                        />
                                                      </Grid>
                                                      <Grid item lg={12} md={12} xl={12} xs={12}>
                                                        <Input
                                                          id="vendaSP"
                                                          label="vendaSP"
                                                          autoComplete="off"
                                                          className="form-control"
                                                          value={
                                                            (
                                                              i == 0 ? (n.vendaUltimaSegunda != null ? n.vendaUltimaSegunda.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :
                                                                i == 1 ? (n.vendaUltimaTerca != null ? n.vendaUltimaTerca.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :
                                                                  i == 2 ? (n.vendaUltimaQuarta != null ? n.vendaUltimaQuarta.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :
                                                                    i == 3 ? (n.vendaUltimaQuinta != null ? n.vendaUltimaQuinta.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :
                                                                      i == 4 ? (n.vendaUltimaSexta != null ? n.vendaUltimaSexta.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :
                                                                        i == 5 ? (n.vendaUltimoSabado != null ? n.vendaUltimoSabado.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :
                                                                          i == 6 ? (n.vendaUltimoDomingo != null ? n.vendaUltimoDomingo.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :
                                                                            ''
                                                            )
                                                          }
                                                          endAdornment={UnidadeTypography(n)}
                                                          inputProps={{
                                                            min: 0,
                                                            step: "0.1"
                                                          }}
                                                        />
                                                      </Grid>
                                                      {/*<Grid item lg={6} md={6} xl={6} xs={6}>*/}
                                                      {/*  <Input*/}
                                                      {/*    id="vendaDiaAnt"*/}
                                                      {/*    label="vendaDiaAnt"*/}
                                                      {/*    autoComplete="off"*/}
                                                      {/*    className="form-control"*/}
                                                      {/*    value={*/}
                                                      {/*      (*/}
                                                      {/*        i == 0 ? (n.vendaAntSegunda != null ? n.vendaAntSegunda.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :*/}
                                                      {/*          i == 1 ? (n.vendaAntTerca != null ? n.vendaAntTerca.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :*/}
                                                      {/*            i == 2 ? (n.vendaAntQuarta != null ? n.vendaAntQuarta.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :*/}
                                                      {/*              i == 3 ? (n.vendaAntQuinta != null ? n.vendaAntQuinta.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :*/}
                                                      {/*                i == 4 ? (n.vendaAntSexta != null ? n.vendaAntSexta.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :*/}
                                                      {/*                  i == 5 ? (n.vendaAntSabado != null ? n.vendaAntSabado.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :*/}
                                                      {/*                    i == 6 ? (n.vendaAntDomingo != null ? n.vendaAntDomingo.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '') :*/}
                                                      {/*                      ''*/}
                                                      {/*      )*/}
                                                      {/*    }*/}
                                                      {/*    endAdornment={(<Typography position="left" className={classes.fontTypographyUnidade}>{(n.totvsInsumo != null ? (n.unidadeProducao != null ? n.unidadeProducao.abreviacao : '') : '')}</Typography>)}*/}
                                                      {/*    inputProps={{*/}
                                                      {/*      min: 0,*/}
                                                      {/*      step: "0.1"*/}
                                                      {/*    }}*/}
                                                      {/*  />*/}
                                                      {/*</Grid>*/}
                                                      {/*
                                                      <Grid item lg={12} md={12} xl={12} xs={12}>
                                                        <Typography align="center" className={classes.fontTypography}>

                                                        </Typography>
                                                      </Grid>
                                                      */}
                                                    </Grid>

                                                  </TableCell>

                                                </>

                                              )

                                            }


                                          </TableRow>
                                        )
                                      })
                                      :
                                      (idLoja != '' ?
                                        isLoadingChecklistOperacaoItem ? Array.apply(null, { length: 6 }).map((e, i) => (
                                          <TableRow hover>
                                            <TableCell component="th" scope="row" align="right" colSpan={12}>
                                              <Skeleton animation="wave" />
                                            </TableCell>
                                          </TableRow>
                                        )) :
                                          <TableRow>
                                            <TableCell rowSpan={2} colSpan={10} align="center">Sem itens no checklist de operação</TableCell>
                                          </TableRow>
                                        :
                                        <TableRow>
                                          <TableCell rowSpan={2} colSpan={10} align="center">Informe uma Loja</TableCell>
                                        </TableRow>
                                      )

                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </>
                      : tipo == 'T' ?
                        <>
                          <Grid container spacing={2}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                              <Grid item xl={6} lg={6} md={6} xs={12} style={{ marginTop: 20 }}>
                                <Input
                                  id="term"
                                  label="term"
                                  placeholder="Filtro"
                                  value={term}
                                  onChange={handleChangeTerm('term')}
                                  fullWidth
                                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                />
                              </Grid>
                              <br />
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell colSpan={2}></TableCell>
                                      {
                                        (
                                          undefined !== dias && dias.length ?
                                            dias.map((dia) =>
                                              <TableCell align="center" colSpan={2}>{moment(dia).format('DD/MM/YYYY')}</TableCell>
                                            )
                                            :
                                            null
                                        )
                                      }
                                    </TableRow>
                                    <TableRow>
                                      <TableCell colSpan={2}>Item</TableCell>
                                      {
                                        (
                                          undefined !== dias && dias.length ?
                                            dias.map((dia) =>
                                              <>
                                                <TableCell>Temp 1</TableCell>
                                                <TableCell>Temp 2</TableCell>
                                              </>
                                            )
                                            :
                                            null
                                        )
                                      }
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {

                                      idLoja !== '' && idLoja !== undefined && undefined !== filteredItens && filteredItens.length && undefined !== dias && dias.length
                                        ?
                                        filteredItens.map((n) => {
                                          return (
                                            <TableRow hover key={(n ? n.id : 0)}>
                                              <TableCell component="th" scope="row" fullWidth colSpan={2}>
                                                <Typography position="left" className={classes.fontTypography}>{n.descricao}</Typography>
                                              </TableCell>

                                              {

                                                dias.map((dia) =>

                                                  <>
                                                    <TableCell component="th" scope="row" align="right">
                                                      <Input
                                                        id="temperatura1"
                                                        label="temperatura1"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        type="number"
                                                        value={getTemperatura1(n.id, dia)}
                                                        onChange={(event) => handleChangeTemperatura1(event, 'temperatura1', n, dia)}
                                                        endAdornment={<Typography position="left" className={classes.fontTypography}>ºC</Typography>}
                                                        inputProps={{
                                                          step: "0.1"
                                                        }}
                                                        disabled={isTemperaturaDisabled(dia)}
                                                      />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                      <Input
                                                        id="temperatura2"
                                                        label="temperatura2"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        type="number"
                                                        value={getTemperatura2(n.id, dia)}
                                                        onChange={(event) => handleChangeTemperatura2(event, 'temperatura2', n, dia)}
                                                        endAdornment={<Typography position="left" className={classes.fontTypography}>ºC</Typography>}
                                                        inputProps={{
                                                          step: "0.1"
                                                        }}
                                                        disabled={isTemperaturaDisabled(dia)}
                                                      />
                                                    </TableCell>
                                                  </>

                                                )

                                              }


                                            </TableRow>
                                          )
                                        })
                                        :
                                        (idLoja != '' ?
                                          isLoadingChecklistOperacaoItem ? Array.apply(null, { length: 6 }).map((e, i) => (
                                            <TableRow hover>
                                              <TableCell component="th" scope="row" align="right" colSpan={9}>
                                                <Skeleton animation="wave" />
                                              </TableCell>
                                            </TableRow>
                                          )) :
                                            <TableRow>
                                              <TableCell rowSpan={2} colSpan={10} align="center">Sem itens no checklist de operação</TableCell>
                                            </TableRow>
                                          :
                                          <TableRow>
                                            <TableCell rowSpan={2} colSpan={10} align="center">Informe uma Loja</TableCell>
                                          </TableRow>
                                        )

                                    }
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </>
                        :
                        <>
                          <Grid container spacing={2}>
                            <Grid item lg={12} md={12} xl={12} xs={12}>
                              <Grid item xl={6} lg={6} md={6} xs={12} style={{ marginTop: 20 }}>
                                <Input
                                  id="term"
                                  label="term"
                                  placeholder="Filtro"
                                  value={term}
                                  onChange={handleChangeTerm('term')}
                                  fullWidth
                                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                />
                              </Grid>
                              <br />
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell colSpan={2}>Item</TableCell>
                                      {
                                        (
                                          undefined !== dias && dias.length ?
                                            dias.map((dia) =>
                                              <TableCell>{moment(dia).format('DD/MM/YYYY')}</TableCell>
                                            )
                                            :
                                            null
                                        )
                                      }
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {

                                      idLoja !== '' && idLoja !== undefined && undefined !== filteredItens && filteredItens.length && undefined !== dias && dias.length
                                        ?
                                        filteredItens.map((n) => {
                                          return (
                                            <TableRow hover key={(n ? n.id : 0)}>
                                              <TableCell component="th" scope="row" fullWidth colSpan={2}>
                                                <Typography position="left" className={classes.fontTypography}>{n.descricao}</Typography>
                                              </TableCell>

                                              {

                                                dias.map((dia) =>

                                                  <TableCell component="th" scope="row">
                                                    <Checkbox
                                                      onClick={(event) => handleChangeCheck(event, 'check', n, dia)}
                                                      color="primary"
                                                      checked={getCheck(n.id, dia)}
                                                    />
                                                  </TableCell>

                                                )

                                              }


                                            </TableRow>
                                          )
                                        })
                                        :
                                        (idLoja != '' ?
                                          isLoadingChecklistOperacaoItem ? Array.apply(null, { length: 6 }).map((e, i) => (
                                            <TableRow hover>
                                              <TableCell component="th" scope="row" align="right" colSpan={9}>
                                                <Skeleton animation="wave" />
                                              </TableCell>
                                            </TableRow>
                                          )) :
                                            <TableRow>
                                              <TableCell rowSpan={2} colSpan={10} align="center">Sem itens no checklist de operação</TableCell>
                                            </TableRow>
                                          :
                                          <TableRow>
                                            <TableCell rowSpan={2} colSpan={10} align="center">Informe uma Loja</TableCell>
                                          </TableRow>
                                        )

                                    }
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </>
                    ) : null
                  )}
                </CardContent>
                {/*<Divider />*/}
                {/*<CardActions>*/}
                {/*  <Grid item lg={12} md={12} xl={12} xs={12} style={{*/}
                {/*    display: 'flex',*/}
                {/*    alignItems: 'right',*/}
                {/*    flexDirection: 'row',*/}
                {/*    textAlign: 'right',*/}
                {/*    justifyContent: 'right'*/}
                {/*  }}>*/}
                {/*    <Button*/}
                {/*      color="default"*/}
                {/*      onClick={() => history.push('/checklistsOperacoesCards/categorias')}*/}
                {/*      variant="contained"*/}
                {/*    >*/}
                {/*      Cancelar*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*      color="primary"*/}
                {/*      variant="contained"*/}
                {/*      onClick={() => handlePrint()}*/}
                {/*      disabled={props.respostaChecklistOperacao.isLoading}*/}
                {/*      startIcon={<PrintIcon />}*/}
                {/*      endIcon={props.respostaChecklistOperacao.isLoading && <CircularProgress size={24} />}*/}
                {/*    >*/}
                {/*      {'Imprimir'}*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*      color="primary"*/}
                {/*      type="submit"*/}
                {/*      variant="contained"*/}
                {/*      disabled={props.respostaChecklistOperacao.isLoading}*/}
                {/*      startIcon={<SaveIcon />}*/}
                {/*      endIcon={props.respostaChecklistOperacao.isLoading && <CircularProgress size={24} />}*/}
                {/*    >*/}
                {/*      {params.id ? (props.respostaChecklistOperacao.isLoading ? 'Atualizando resposta de checklist...' : 'Atualizar') : (props.respostaChecklistOperacao.isLoading ? 'Salvando resposta de checklist...' : 'Salvar')}*/}
                {/*    </Button>*/}
                {/*  </Grid>*/}
                {/*</CardActions>*/}
              </Card>
              <Grid container className={classes.stickyButtons}>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Typography style={{ fontSize: "15px", paddingLeft: 10, paddingTop: 4 }}>
                    {(tipo == 'T' ? 'Refrigeradores: entre 0°C e 5°C e Freezers: abaixo de -18°C' : '')}
                  </Typography>
                </Grid>
                <Grid container lg={12} md={12} xl={12} xs={12} className={classes.buttonGrid}>
                  <Grid item>
                    <Button
                      className={classes.button}
                      color="default"
                      onClick={() => history.push('/checklistsOperacoesCards/categorias')}
                      variant="contained"
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  {
                    !isMobile ?
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handlePrint()}
                          disabled={props.respostaChecklistOperacao.isLoading}
                          startIcon={<PrintIcon />}
                          endIcon={props.respostaChecklistOperacao.isLoading && <CircularProgress size={24} />}
                        >
                          {'Imprimir'}
                        </Button>
                      </Grid>
                    : null
                  }
                  <Grid item>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={props.respostaChecklistOperacao.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.respostaChecklistOperacao.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.respostaChecklistOperacao.isLoading ? 'Atualizando resposta de checklist...' : 'Atualizar') : (props.respostaChecklistOperacao.isLoading ? 'Salvando resposta de checklist...' : 'Salvar')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
      </Grid >
    </Page >
  );
}

RespostaChecklistOperacaoEdit.propTypes = {
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => {
  return state
}

const connectedRespostaChecklistOperacaoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RespostaChecklistOperacaoEdit))
)
export { connectedRespostaChecklistOperacaoEditPage as RespostaChecklistOperacaoEdit };
