import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Tooltip, Typography, Avatar } from '@material-ui/core'
import LocalAtmTwoToneIcon from '@material-ui/icons/LocalAtmTwoTone'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { AccessControl } from '../../../../components/Utils/AccessControl'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 46,
    width: 46
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}))

const CuponsChartCard = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()

  const cuponsFidelizados = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.cuponsFidelizados : undefined) : undefined))
  const rankingCuponsFidelizados = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.rankingCuponsFidelizados : undefined) : undefined))
  const totalLojas = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.totalLojas : undefined) : undefined))
  const valorCuponsFidelizados = useSelector((state) => (state.dashboard ? (state.dashboard.dashboardLoja ? state.dashboard.dashboardLoja.valorCuponsFidelizados : undefined) : undefined))

  const { isLoading } = useSelector((state) => state.dashboard)

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              CUPONS FIDELIZADOS
            </Typography>
            <Tooltip title='Quantidade de notas pontuadas no App'>
              <Typography variant="h3">
                {isLoading ? (
                  <Skeleton />
                ) : (
                  (isNaN(cuponsFidelizados) ? 0 : cuponsFidelizados)
                )}
              </Typography>
            </Tooltip>
            <AccessControl
              rule={'relatorios'} //permissão necessária para acessar conteúdo
              yes={() => (
                <>
                  <Typography variant="body2">
                    TOTALIZANDO
                  </Typography>
                  <Tooltip title='Valor total R$ das notas pontuadas no App'>
                    <Typography variant="h3">
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(valorCuponsFidelizados)
                      )}
                    </Typography>
                  </Tooltip>
                </>
              )}
            />
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <LocalAtmTwoToneIcon className={classes.icon} />
            </Avatar>
            <Typography variant="h5" style={{ marginTop: 5 }}>
              {isLoading ? (
                <Skeleton />
              ) : (
                (rankingCuponsFidelizados != null ? rankingCuponsFidelizados + 'º / ' + totalLojas : '')
              )}
            </Typography>
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <Typography className={classes.caption} variant="caption">
            Desde de 1 de janeiro de 2020
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  )
}

CuponsChartCard.propTypes = {
  className: PropTypes.string
}

export default CuponsChartCard
