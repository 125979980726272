import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { relatorioAction, totvsInsumoAction } from '../../_actions'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core'
import {
  TextValidator,
  ValidatorForm
} from 'react-material-ui-form-validator'
import Skeleton from "@material-ui/lab/Skeleton"
import { withRouter } from 'react-router-dom'
import TitleContentInformation from '../TitleContentInformation'
import { AccessControl } from '../../components/Utils/AccessControl'
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify'
import moment from 'moment'
import palette from 'theme/palette'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  }
})

const RelatorioVendaInsumo = (props) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const relatorio = props.relatorio.relatorio.lista
  const ultimaVendaImportada = props.relatorio.relatorio.ultimaVendaImportada
  const { totalRegistros } = props.relatorio
  const { isLoading } = props.relatorio
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [aproveitamento, setAproveitamento] = React.useState(true);
  const [disablePrint, setDisablePrint] = React.useState(false)

  const [dataInicio, setDataInicio] = React.useState(undefined)
  const [dataFim, setDataFim] = React.useState(undefined)
  const [open, setOpen] = React.useState(false)

  const { idLoja } = useSelector(
    (state) => state.usuarioLoja
  )

  const { totvsInsumo } = useSelector(
    (state) => state.totvsInsumo
  )

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
      setDisablePrint(true)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selectedInsumo = totvsInsumo.filter(
        (item) => item.value == obj.id
      )
      dispatch(
        totvsInsumoAction.changeDetailsTotvsInsumo(
          selectedInsumo[0]
        )
      )
    }

    setOpen(false)
  }

  const options = totvsInsumo.map(u => ({ id: u.value, nome: u.text }));

  useEffect(() => {
    const dataIni = moment().subtract(1, "months")
    const dataFi = moment();

    setDataInicio(moment(dataIni).format("YYYY-MM-DD"));
    setDataFim(moment(dataFi).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    dispatch(totvsInsumoAction.getTotvsInsumoSelect());

    dispatch(relatorioAction.clear());
  }, [])

  const handleSubmit = (event) => {

    dispatch(relatorioAction.clear());

    if (value?.id == undefined || value?.id == 0) {
      toast.error('Informe um insumo!')
    }

    if (dataInicio == undefined) {
      toast.error('Informe uma Data de Início!')
    }

    if (dataFim == undefined) {
      toast.error('Informe uma Data Final!')
    }

    if (dataFim < dataInicio) {
      toast.error('Data Final deve ser maior que a Data de Início!')
    }

    let payload = {
      IdLoja: idLoja ? idLoja : 0,
      DataInicial: moment(dataInicio).format("YYYY-MM-DD"),
      DataFinal: moment(dataFim).format("YYYY-MM-DD"),
      CodigoProduto: value ? value.id : 0,
      Aproveitamento: aproveitamento || false
    };
    dispatch(relatorioAction.getRelatorioVendasInsumos(payload, true));
  };

  const handlePrint = (event) => {
    event.preventDefault()
    window.open('/relatorioVendaInsumo', '_blank');
  }

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {

  }, [props.relatorio.isLoading])

  useEffect(() => {
    if (props.relatorio.relatorio && props.relatorio.relatorio.lista) {
      setDisablePrint(false)
    }
  }, [props.relatorio.relatorio])

  const handleChangeDataInicio = (e) => {
    setDataInicio(e.target.value)
  }

  const handleChangeDataFim = (e) => {
    setDataFim(e.target.value)
  }

  const handleChangeAproveitamento = (e) => {
    setAproveitamento(e.target.checked)
  }

  function getTotal() {
    if (relatorio && relatorio.length > 0) {

      let total = relatorio.reduce(function (acumulador, valorAtual) {
        return acumulador + valorAtual.quantidade;
      }, 0)

      return total.toLocaleString('pt-br', { minimumFractionDigits: 2 });

    } else {
      return (0.00).toLocaleString('pt-br', { minimumFractionDigits: 2 });;
    }
  }

  const getColor = () => {
    var diff = moment().diff(moment(ultimaVendaImportada, 'DD/MM/YYYY'), 'days')

    return diff > 1 ? palette.error.main : palette.text.primary
  }

  function TotalRow() {
    return (
      undefined !== relatorio && relatorio.length
      ?
        <TableRow>
          <TableCell rowSpan={3} colSpan={idLoja == 0 ? 4 : 3}><b>Total</b></TableCell>
          <TableCell rowSpan={3} align="right"><b>{getTotal()}</b></TableCell>
        </TableRow>
      :
      null
    )
  }


  return (
    <Page className={classes.root} title="Relatório - Vendas de Insumos">
      <AccessControl
        rule={'relatorios.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <Grid
            {...rest}
            container
            spacing={2}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card style={{ marginBottom: 10, marginTop: 0 }}>
                <ValidatorForm
                  id="formRelatorioVendasInsumos"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardContent>
                    <TitleContentInformation
                      subTitle={'RELATÓRIO'}
                      title={'Vendas de Insumos'}                     
                    />
                    <Grid alignItems="space-between" container spacing={1} style={{ marginTop: 10 }}>
                      <Grid item>
                        <Typography component="h2" gutterBottom variant="overline">Última atualização:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography component="h2" gutterBottom variant="overline" style={{ color: getColor() }}>{(ultimaVendaImportada != null && ultimaVendaImportada != '' ? ultimaVendaImportada : '')}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Início *"
                          type="date"
                          value={dataInicio}
                          onChange={handleChangeDataInicio}
                          id="dataInicio"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          InputLabelProps={{ shrink: true }}
                          className={classes.textField}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="Final *"
                          type="date"
                          value={dataFim}
                          onChange={handleChangeDataFim}
                          id="dataFim"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={2}>
                        <CardContent>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={aproveitamento}
                                onChange={handleChangeAproveitamento}
                                color="primary"
                              />
                            }
                            label="Aproveitamento"
                          />
                        </CardContent>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ alignItems: 'center', justifyContent: 'left', alignContent: 'left' }}>
                      <Grid item xl={6} lg={6} md={6} xs={12} >
                        <Autocomplete
                          value={value}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={options}
                          defaultValue={null}
                          getOptionLabel={option => option.nome}
                          renderInput={(params) => <TextValidator {...params} label="SELECIONE UM INSUMO... *"
                            variant="outlined" fullWidth />}
                          style={{ width: "312px" }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} xs={12} style={{
                        alignItems: 'bottom',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'right',
                        textAlign: 'right'
                      }}>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={props.relatorio.isLoading}
                            startIcon={<SearchIcon />}
                            endIcon={props.relatorio.isLoading && <CircularProgress size={24} />}
                          >
                            {props.relatorio.isLoading ? 'Pesquisando...' : 'Pesquisar'}
                          </Button>
                        </Grid>
                        <Grid>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={handlePrint}
                            variant="outlined"
                            disabled={props.relatorio.isLoading || disablePrint}
                            startIcon={<PrintIcon />}
                          >
                            Imprimir
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </ValidatorForm>
              </Card>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TotalRow />
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        {idLoja == 0 ? <TableCell>Loja</TableCell> : null}
                        <TableCell>Produto</TableCell>
                        <TableCell>Chave Nota</TableCell>
                        <TableCell align="right">Quantidade (g/ml/un)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {undefined !== relatorio && relatorio.length
                        ? relatorio.map((n) => {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {/*SOLUÇÃO PROVISÓRIA ATÉ AJUSTAR GMT -3 */}
                                {n.data && moment.utc(n.data).format("DD/MM/YYYY HH:mm")}
                              </TableCell>
                              {idLoja == 0 ?
                                <TableCell component="th" scope="row">
                                  {n.loja ? n.loja : ''}
                                </TableCell>
                                : null
                              }
                              <TableCell component="th" scope="row">
                                {n.descricaoProduto}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {n.chaveNota || ''}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                {n.quantidade ? n.quantidade.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        :
                        (value != null ?
                          (isLoading ?
                            Array.apply(null, { length: 6 }).map((e, i) => (
                              <TableRow hover>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                {idLoja == 0 ?
                                  <TableCell component="th" scope="row">
                                    <Skeleton animation="wave" />
                                  </TableCell>
                                  : null
                                }
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Skeleton animation="wave" />
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                  <Skeleton animation="wave" />
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow>
                              {idLoja == 0 ?
                                <TableCell rowSpan={3} colSpan={5} align="center">Nenhum registro encontrado</TableCell>
                                :
                                <TableCell rowSpan={3} colSpan={4} align="center">Nenhum registro encontrado</TableCell>
                              }
                            </TableRow>
                          )
                          :
                          <TableRow>
                            {idLoja == 0 ?
                              <TableCell rowSpan={3} colSpan={5} align="center">Informe um Período e Insumo</TableCell>
                              :
                              <TableCell rowSpan={3} colSpan={4} align="center">Informe um Período e Insumo</TableCell>
                            }
                          </TableRow>
                        )
                      }
                      <TotalRow />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
RelatorioVendaInsumo.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    relatorio: state.relatorio
  }
}
const connectedRelatorioVendaInsumoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RelatorioVendaInsumo))
)
export { connectedRelatorioVendaInsumoPage as RelatorioVendaInsumo }
