import { history } from 'utils'
import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'
import { authActions } from '../_actions/'

export const listaPrecoAction = {
  getListaPreco,
  addListaPreco,
  getListaPrecoById,
  getListaPrecoArquivo,
  onChangeProps,
  editListaPrecoInfo,
  editListaPrecosDetails,
  createListaPreco,
  deleteListaPrecoById,
  clear,
  clearAll,
  clearListaPrecosLojas,
  clearListaPrecosProdutos
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: '',
  Order: 'titulo',
  Direction: 'asc'
};

function getListaPreco(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'listasPrecos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeListaPrecosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      })
  }
}

function createListaPreco(payload, props) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'listasPrecos/'
    crudService.post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(createListaPrecoInfo())
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/listasPrecos') // retorna para a lista e atualiza
        } else {
          toast.err('Oops! Erro ao cadastrar lista de preço! 😥')
        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar lista de preço! 😥')
        return err.response;
      })
  }
}


function getListaPrecoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'listasPrecos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editListaPrecosDetails(response.data))
    })
  }
}

function getListaPrecoArquivo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'listasPrecos/arquivo' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeListaPrecosList(response.data.items, response.data.totalRows))
        const BOM = '\uFEFF';
        var FileSaver = require('file-saver');
        var blob = new Blob([BOM + response.data], { type: "text/csv;charset=utf-8" });
        FileSaver.saveAs(blob, "listas_precos.csv");
        toast.success('Feito! Download do arquivo realizado com sucesso! 😎')
        dispatch(notIsLoading())
        dispatch(clear())
        getListaPreco()
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'listasPrecosProdutos') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      if (event.target.type === 'checkbox') {
        dispatch(handleOnChangeProps(props, event.target.checked))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}

function editListaPrecoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'listasPrecos/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          var ret = JSON.parse(response.request.responseText);
          dispatch(updatedListaPrecoInfo())
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/listasPrecos')
        }// retorna para a lista e atualiza        }
      }).catch((err) => {
        //  console.log(err.response);
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao atualizar lista de preço! 😥')
        return err.response;
      })
  }
}

function deleteListaPrecoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'listasPrecos/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteListaPrecosDetails())
      dispatch(getListaPreco(filterModel))
      dispatch(notIsLoading())
    })
  }
}

export function addListaPreco(payload, props) {
  return [{ type: 'ADD_LISTA_PRECO', listaPreco: payload },
  clear()
  ]
}

export function changeListaPrecosList(listaPreco, totalRows) {
  return {
    type: 'FETCHED_ALL_LISTA_PRECO',
    listaPreco: listaPreco,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'LISTA_PRECO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'LISTA_PRECO_CLEAR_ALL'
  }
}

export function clearListaPrecosLojas() {
  return {
    type: 'LISTA_PRECO_CLEAR_LOJAS'
  }
}

export function clearListaPrecosProdutos() {
  return {
    type: 'LISTA_PRECO_CLEAR_PRODUTOS'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'LISTA_PRECO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editListaPrecosDetails(listaPreco) {
  return {
    type: 'LISTA_PRECO_DETAIL',
    id: listaPreco.id,
    nome: listaPreco.nome,
    idFornecedor: listaPreco.idFornecedor,
    todasLojas: listaPreco.todasLojas,
    listasPrecosLojas: listaPreco.listasPrecosLojas,
    listasPrecosProdutos: listaPreco.listasPrecosProdutos,
    excluido: listaPreco.excluido,
  }
}

export function updatedListaPrecoInfo() {
  return {
    type: 'LISTA_PRECO_UPDATED'
  }
}

export function createListaPrecoInfo() {
  return {
    type: 'LISTA_PRECO_CREATED_SUCCESSFULLY'
  }
}

export function deleteListaPrecosDetails() {
  return {
    type: 'DELETED_LISTA_PRECO_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'LISTA_PRECO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'LISTA_PRECO_NOTISLOADING'
  }
}
