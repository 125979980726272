import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ThumbUpTwoToneIcon from '@material-ui/icons/ThumbUpTwoTone';
import ThumbDownTwoToneIcon from '@material-ui/icons/ThumbDownTwoTone';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import moment from 'moment';
import { styled } from '@material-ui/styles';

const ComprasCollapsableRow = ({ row, idLoja }) => {
  const [open, setOpen] = useState(false);

  const CustomTableCell = styled(TableCell)(() => ({ borderBottom: 0 }));

  return (
    <React.Fragment>
      <TableRow hover style={{ textDecorationLine: row.cancelado ? 'line-through' :'none' }}>
        <TableCell component="th" scope="row">
          {moment(row.data).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.nomeFornecedor || ''}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.fornecedor || ''}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.loja || ''}
        </TableCell>
        <TableCell>
          {open ?
            <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
            :
            <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={idLoja ? 6 : 7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Produto</TableCell>
                    <TableCell>NCM</TableCell>
                    <TableCell></TableCell>
                    <TableCell>CEAN/GTIN</TableCell>
                    <TableCell>Un.</TableCell>
                    <TableCell>Qntd.</TableCell>
                    <TableCell>Valor Un.</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Insumo</TableCell>
                    <TableCell>Valor Un. Conv.</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.comprasProdutos?.length > 0 && row.comprasProdutos?.map((cp) => (
                    <TableRow key={cp.id}>
                      <CustomTableCell>{cp.descricao}</CustomTableCell>
                      <CustomTableCell>{cp.ncm}</CustomTableCell>

                      <CustomTableCell>
                        <TableRow>
                          <TableCell align="right"><b>Comercial</b></TableCell>
                        </TableRow>
                        <TableRow>
                          <CustomTableCell align="right"><b>Tributável</b></CustomTableCell>
                        </TableRow>
                      </CustomTableCell>

                      <CustomTableCell>
                        <TableRow>
                          <TableCell>
                            {cp.cean}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <CustomTableCell>
                            {cp.ceanTributavel}
                          </CustomTableCell>
                        </TableRow>
                      </CustomTableCell>

                      <CustomTableCell>
                        <TableRow>
                          <TableCell>
                            {cp.unidade && cp.unidade.abreviacao ? cp.unidade.abreviacao : ''}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <CustomTableCell>
                            {cp.unidadeTributavel && cp.unidadeTributavel.abreviacao ? cp.unidadeTributavel.abreviacao : ''}
                          </CustomTableCell>
                        </TableRow>
                      </CustomTableCell>

                      <CustomTableCell>
                        <TableRow>
                          <TableCell>
                            {cp.quantidade}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <CustomTableCell>
                            {cp.quantidadeTributavel}
                          </CustomTableCell>
                        </TableRow>
                      </CustomTableCell>

                      <CustomTableCell>
                        <TableRow>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {cp.valorUnitario ? 'R$ ' + cp.valorUnitario.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <CustomTableCell style={{ whiteSpace: 'nowrap' }}>
                            {cp.valorUnitarioTributavel ? 'R$ ' + cp.valorUnitarioTributavel.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                          </CustomTableCell>
                        </TableRow>
                      </CustomTableCell>

                      <CustomTableCell>
                        <TableRow>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            {cp.valor ? 'R$ ' + cp.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <CustomTableCell style={{ whiteSpace: 'nowrap' }}>
                            {cp.valorTributavel ? 'R$ ' + cp.valorTributavel.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                          </CustomTableCell>
                        </TableRow>
                      </CustomTableCell>

                      <CustomTableCell><b>{cp.totvsInsumo?.nome || ''}</b></CustomTableCell>

                      <CustomTableCell style={{ whiteSpace: 'nowrap' }}>
                        <b>{cp.valorUnitarioConvertido ? 'R$ ' + cp.valorUnitarioConvertido.toLocaleString('pt-br', { minimumFractionDigits: 5 }) : ''}</b> / {cp.totvsInsumo?.unidade?.abreviacao}
                      </CustomTableCell>

                      <CustomTableCell style={{ whiteSpace: 'nowrap' }}>
                        {cp.aprovado ?
                          <Tooltip title="Produto Aprovado">
                            <ThumbUpTwoToneIcon />
                          </Tooltip>
                          :
                          <Tooltip title="Produto Reprovado">
                            <ThumbDownTwoToneIcon />
                          </Tooltip>
                        }
                      </CustomTableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ComprasCollapsableRow
